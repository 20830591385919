export default {
  clickOutside: {
    beforeMount(el, binding) {
      const element = el;
      function clickHandler(e) {
        // 这里判断点击的元素是否是本身，是本身，则返回
        if (element.contains(e.target)) {
          return false;
        }
        // 判断指令中是否绑定了函数
        if (binding.value && typeof binding.value === 'function') {
          // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
          binding.value(e);
        }
        return true;
      }
      // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
      element.vueClickOutside = clickHandler;
      document.addEventListener('click', clickHandler);
    },
    unmounted(el) {
      const element = el;
      document.removeEventListener('click', element.vueClickOutside);
      delete element.vueClickOutside;
    },
  },
};
