import { reactive, computed } from 'vue';

const present = reactive({});

//
function setPresentModel(model) {
  present.model = model;
}

//
function getPresentModel() {
  return present.model;
}

function setMatrixIndex(index) {
  present.matrixIndex = index;
}

function getMatrixIndex() {
  return computed(() => present.matrixIndex);
}

function setPresentMatrix(matrix) {
  present.matrix = matrix;
}

function getPresentMatrix() {
  return present.matrix;
}

function setPresentTable(tableData) {
  present.table = tableData;
}

function getPresentTable() {
  return present.table;
}

function setReplaceModelStatus(status) {
  present.replaceModel = status;
}
function getReplaceModelStatus() {
  return present.replaceModel;
}

export default {
  setPresentModel,
  getPresentModel,
  setMatrixIndex,
  getMatrixIndex,
  setPresentMatrix,
  getPresentMatrix,

  setPresentTable,
  getPresentTable,
  setReplaceModelStatus,
  getReplaceModelStatus,
};
