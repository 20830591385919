<template>
  <div class="library-head">
    <class-panel></class-panel>
    <filter-panel></filter-panel>
  </div>
</template>
<script>
import classPanel from './classPanel.vue';
import filterPanel from './filterPanel.vue';

export default {
  name: 'libraryHead',
  components: {
    classPanel,
    filterPanel,
  },
  setup() {
    return {};
  },
}
</script>
<style lang="scss" scoped>
.library-head {
  border: 1px solid #efefef;
}
</style>
