<template>
  <div class="image-panel" v-show="visible">
    <div class="panel-block">
      <el-collapse accordion>
        <el-collapse-item title="边框" name="1">
          <div class="row-chunk">
            <span class="row-title">方向</span>
            <el-select v-model="border.dir" @change="borderChange('', $event)">
              <el-option v-for="item in borderDirList" :key="item.value" :value="item.value" :label="item.name"></el-option>
            </el-select>
          </div>
          <div class="row-chunk">
            <span class="row-title">宽度</span>
            <el-input v-model="border.width" placeholder="按回车键设置宽度" @change="borderChange('Width', $event)">
              <template #suffix>
                <span>像素</span>
              </template>
            </el-input>
          </div>
          <div class="row-chunk">
            <span class="row-title">样式</span>
            <el-select v-model=" border.style" @change="borderChange('Style', $event)">
              <el-option v-for="item in borderStyleList" :key="item.value" :value="item.value" :label="item.key"></el-option>
            </el-select>
          </div>
          <div class="row-chunk">
            <span class="row-title">颜色</span>
            <el-color-picker v-model="border.color"  @change="borderChange('Color', $event)"></el-color-picker>
          </div>
        </el-collapse-item>
        <el-collapse-item title="圆角" name="2">
          <div class="row-chunk">
            <el-input v-model="radius" placeholder="按回车键设置圆角" @change="setBorderRadius">
              <template #suffix>
                <span>像素</span>
              </template>
            </el-input>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, ref, reactive } from 'vue';

const borderDirList = [
  {
    name: '全部',
    value: 'border',
  },
  {
    name: '顶边',
    value: 'borderTop',
  },
  {
    name: '右边',
    value: 'borderRight',
  },
  {
    name: '底边',
    value: 'borderBottom',
  },
  {
    name: '左边',
    value: 'borderLeft',
  },
];
const borderStyleList = [
  {
    key: '实线',
    value: 'solid',
  },
  {
    key: '虚线',
    value: 'dashed',
  },
  {
    key: '点状虚线',
    value: 'dotted',
  },
];
export default defineComponent({
  name: 'imagePanel',
  setup() {
    const IEditor = inject('IEditor');
    const modelData = ref(null);

    const visible = ref(false);
    const border = reactive({});
    const radius = ref(null);

    IEditor.utils.emitter.on('menuChange', (data) => {
      const { model, menuType } = data;
      modelData.value = model;
      radius.value = model.style.borderRadius ? Number.parseInt(model.style.borderRadius, 10) : null;
      visible.value = menuType === 'image';
    });

    /**
     * 设置边框
     * @param {String} attr 属性名，包含width，style，color
     * @param {String} val 属性值
     */
    function borderChange(attr, val) {
      const styleName = `${border.dir}${attr}`;
      const newVal = attr === 'Width' ? `${val}px` : val;
      const styleVal = `${attr ? newVal : `${border.width}px ${border.style || 'solid'} ${border.color}`}`;
      const oldVal = modelData.value.style[styleName];
      IEditor.utils.undo.record({
        do: () => {
          modelData.value.style[styleName] = styleVal;
        },
        undo: () => {
          modelData.value.style.borderRadius = oldVal;
        },
      });
    }

    /**
     * 设置圆角
     * @param {Number} val
     */
    function setBorderRadius(val) {
      const oldVal = modelData.value.style.borderRadius;
      IEditor.utils.undo.record({
        do: () => {
          modelData.value.style.borderRadius = `${val}px`;
        },
        undo: () => {
          modelData.value.style.borderRadius = oldVal;
        },
      });
    }

    return {
      visible,
      border,
      radius,
      borderChange,
      setBorderRadius,
      borderDirList,
      borderStyleList,
    };
  },
});
</script>
<style scoped lang="scss">

</style>
