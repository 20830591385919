import { isBlock, isContainer } from '../../utils/check';

const NODE_CONTAINERS = ['section', 'p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

async function elementInitial(element, action) {
  return Promise.resolve(element && element.style[action.format] === action.value);
}

async function findStyleNode(node, style, containers = NODE_CONTAINERS) {
  if (node.nodeName.toUpperCase() === 'HTML' || node.nodeName.toUpperCase() === 'BODY') {
    return null;
  }

  if (!node.parentNode) {
    return null;
  }

  if (isContainer(containers, node)) {
    return null;
  }

  const hasStyle = node.style[style] !== null && node.style[style] !== undefined && node.style[style] !== '';

  if (hasStyle) {
    return node;
  }
  const result = await findStyleNode(node.parentNode, style);

  return result;
}

async function getStyleValue(elem, action) {
  if (!elem) {
    return 'initial';
  }

  if (await elementInitial(elem, action)) {
    return 'initial';
  }

  const styleElem = await findStyleNode(elem, action.style);

  if (styleElem && await elementInitial(styleElem, action)) {
    return 'initial';
  }

  return action.value;
}

async function cleanChildrenStyle(action, node) {
  if (!node.hasChildNodes()) {
    return;
  }

  const children = Array.from(node.children)
    .filter((element) => element.style[action.style] !== undefined && element.style[action.style] !== '');

  if (children && children.length > 0) {
    children.forEach((element) => {
      const elem = element;
      elem.style[action.style] = '';

      if (elem.getAttribute('style') === '' || elem.style === null) {
        elem.removeAttribute('style');
      }
    });
  }

  const cleanChildrenChildren = Array.from(node.children)
    .map((element) => cleanChildrenStyle(action, element));

  if (!cleanChildrenChildren || cleanChildrenChildren.length <= 0) {
    return;
  }

  await Promise.all(cleanChildrenChildren);
}

// async function updateSelection(elem, action, containers) {
//   const element = elem;
//   element.style[action.style] = await getStyleValue(element, action, containers);
//   await cleanChildrenStyle(action, element);
// }

async function createSpan(elem, action) {
  const span = document.createElement('span');
  span.style[action.format] = await getStyleValue(elem, action);
  return span;
}

async function createBlockElement(elem, action) {
  const blockElement = document.createElement('section');
  blockElement.style[action.format] = await getStyleValue(elem, action);
  return blockElement;
}

async function createElement(fragment, elem, action) {
  const { childNodes } = fragment;
  const isInclude = [...childNodes].some((node) => isBlock(node)); // 选中区间有block类型元素，则创建section元素进行包裹
  if (isInclude) {
    const blockElement = await createBlockElement(elem, action);
    blockElement.appendChild(fragment);
    return blockElement;
  }
  const span = await createSpan(elem, action);
  span.appendChild(fragment);
  return span;
}

async function flattenChildren(action, node) {
  if (!node.hasChildNodes()) {
    return;
  }

  const children = Array.from(node.children).filter((element) => {
    const style = element.getAttribute('style');
    return !style || style === '';
  });

  if (children && children.length > 0) {
    children.forEach((element) => {
      if (isBlock(element)) {
        return;
      }
      const styledChildren = element.querySelectorAll('[style]');
      if (!styledChildren || styledChildren.length === 0) {
        const text = document.createTextNode(element.textContent);
        element.parentElement.replaceChild(text, element);
      }
    });

    return;
  }

  const flattenChildrenChildren = Array.from(node.children)
    .map((element) => flattenChildren(action, element));

  if (!flattenChildrenChildren || flattenChildrenChildren.length <= 0) {
    return;
  }

  await Promise.all(flattenChildrenChildren);
}

async function replaceSelection(elem, selection, action) {
  const range = selection.getRangeAt(0);

  // // User selected a all list?
  // if (range.commonAncestorContainer && ['ol', 'ul', 'dl'].some((listType) => listType === range.commonAncestorContainer.nodeName.toLowerCase())) {
  //   await updateSelection(range.commonAncestorContainer, action, containers);
  //   return;
  // }

  const fragment = range.extractContents();
  const element = await createElement(fragment, elem, action);
  // console.log('replace element', element);

  await cleanChildrenStyle(action, element);
  await flattenChildren(action, element);

  range.insertNode(element);
  selection.selectAllChildren(element);
  return element;
}

export default async function updateContent(selection, IEditor, action) {
  if (!selection) {
    return;
  }

  const { anchorNode } = selection;
  if (!anchorNode) {
    return;
  }
  console.log('anchorNode', anchorNode);

  const element = (anchorNode.nodeType !== Node.TEXT_NODE
    && anchorNode.nodeType !== Node.COMMENT_NODE) ? anchorNode : anchorNode.parentElement;
  console.log('element', element);

  // const { collapsed } = selection.getRangeAt(0);
  // const sameSelection = element && (element.innerText === selection.toString() || collapsed);
  // console.log('sameSelection', sameSelection);

  // if (sameSelection && isContainer(containers, element) && element.style[action.style] !== undefined) {
  //   await updateSelection(element, action, containers);
  //   return;
  // }
  const innerHtml = IEditor.content.getCurrentTarget().value.innerHTML;
  const model = IEditor.content.getPresentModel();
  const range = IEditor.selection.getAnchorRange();
  IEditor.utils.undo.record({
    do: async () => {
      // console.log('contents', range, range.collapsed);
      if (range.collapsed) {
        // range.selectNode(newElem);
        // selection.selectAllChildren(newElem);
      }
      await replaceSelection(element, selection, action);
    },
    undo: () => {
      model.content = innerHtml;
    },
  });
}
