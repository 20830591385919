import { getElemStyle } from '../../../utils/util';
import ImageNode from './imageNode';
import TextNode from './textNode';
import ColGroup from './colNode';
import ColList from './colListNode';
import TableNode from './tableNode';

function getColorTheme(style) {
  const result = {};
  Object.keys(style).forEach((key) => {
    if (key.includes('color') && key !== 'color') {
      result[key] = style[key];
    }
  });
  return result;
}
export default class NodeModel {
  constructor(node, type) {
    const style = type === 'node' ? getElemStyle(node) : getElemStyle();
    this.data = {
      style,
      openMenu: type,
      colorTheme: getColorTheme(style),
    };
  }

  setChildData(node, type) {
    let model = null;
    switch (type) {
      case 'colList':
        if (!this.colList && !Array.isArray(this.colList)) {
          this.colList = new ColList();
        }
        model = new ColGroup(node);
        this.colList.push(model);
        break;
      case 'image':
        model = new ImageNode(node);
        this[type] = model;
        break;
      case 'text':
        model = new TextNode(node);
        this[type] = model;
        break;
      case 'table':
        model = new TableNode(node);
        console.log('table', model);
        this[type] = model;
        break;
      default:
        break;
    }
    this.menuType = type;
    if (!model) {
      return undefined;
    }
    return model;
  }
}
