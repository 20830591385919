import { isElement } from '../../../utils/check';
import NodeModel from '../nodes/nodeModel';

const directiveUtil = {
  node: (node, model) => {
    const nodeModel = new NodeModel(node, 'node');
    if (model) {
      model.push(nodeModel);
    }
    return nodeModel;
  },
  col: (node, model) => {
    const newModel = model.setChildData(node, 'colList');
    return newModel;
  },
  image: (node, model) => {
    const newModel = model.setChildData(node, 'image');
    return newModel;
  },
  text: (node, model) => {
    const newModel = model.setChildData(node, 'text');
    return newModel;
  },
  table: (node, model) => {
    const newModel = model.setChildData(node, 'table');
    return newModel;
  },
};

function isIgnore(attr) {
  return ['p', 'img', 'tr', 'td'].includes(attr.toLocaleLowerCase());
}

function compileElement(node, model) {
  const directive = node.getAttribute('i-type');
  if (!directive) {
    return null;
  }
  return directiveUtil[directive](node, model);
}

export default function compile(nodes) {
  let resultModel = null;
  function eachNodes(nodeList, basisModel) {
    nodeList.forEach((child) => {
      if (isElement(child) && !isIgnore(child.nodeName)) {
        const model = compileElement(child, basisModel);
        if (!resultModel) {
          resultModel = model;
        }
        const childNodes = [...child.childNodes];
        eachNodes(childNodes, model);
      }
    });
  }
  eachNodes(nodes);
  return resultModel;
}
