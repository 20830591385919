import menus from './menu/menus';

const defaultMenus = { ...menus };

function addMenus(name, menu) {
  if (defaultMenus[name]) {
    return;
  }
  defaultMenus[name] = menu;
}

function filterMenus(names) {
  if (!names) {
    return Object.keys(defaultMenus).map((menuName) => defaultMenus[menuName]);
  }
  return names.map((menuName) => defaultMenus[menuName]);
}

export default {
  addMenus,
  filterMenus,
};
