/* 需要项内容中插入一个块的menu */
import { toggleBlock, isBlockActive } from './reaction';

export default {
  insertImage: {
    text: '插入图片',
    icon: 'editorimg',
    type: 'block',
    active: isBlockActive,
    action: (editor) => toggleBlock(editor, 'image'),
  },
  insertOl: {
    text: '有序列表',
    icon: 'ol',
    type: 'block',
    active: isBlockActive,
    action: (editor) => toggleBlock(editor, 'ol'),
  },
  insertUl: {
    text: '无序列表',
    icon: 'ul',
    type: 'block',
    active: isBlockActive,
    action: (editor) => toggleBlock(editor, 'ul'),
  },
  insertTable: {
    text: '插入表格',
    icon: 'table',
    type: 'block',
    active: isBlockActive,
    action: (editor) => toggleBlock(editor, 'table'),
  },
};
