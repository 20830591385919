export const undoAction = (editor) => {
  editor.utils.undo.undo();
};

export const redoAction = (editor) => {
  editor.utils.undo.redo();
};

export const replaceImageAction = async (editor) => {
  const imageSrc = await editor.utils.uploadFile();
  const { model: cellModel } = editor.content.getPresentModel();
  if (imageSrc) {
    const oldVal = cellModel.src;
    editor.utils.undo.record({
      do: () => {
        cellModel.src = imageSrc;
      },
      undo: () => {
        cellModel.src = oldVal;
      },
    });
  }
};

/**
 * 插入行
 * @param {Object} editor 编辑器实例
 */
export const insertRow = (editor) => {
  const tableData = editor.content.getPresentTable();
  if (!tableData) {
    return;
  }
  const trModelData = editor.model.producer.trModel(tableData.trModel.tds.length);
  tableData.table.insert(tableData.trIndex, trModelData);
};

/**
 * 插入列
 * @param {Object} editor 编辑器实例
 */
export const insertCol = (editor) => {
  const tableData = editor.content.getPresentTable();
  if (!tableData) {
    return;
  }
  const { table, tdIndex } = tableData;
  const tdModel = editor.model.producer.tdModel();

  table.trs.forEach((tr) => {
    tr.insert(tdIndex, tdModel);
  });
};

/**
 * 删除行
 * @param {Object} editor 编辑器实例
 */
export const removeRow = (editor) => {
  const tableData = editor.content.getPresentTable();
  if (!tableData) {
    return;
  }
  const { table, trIndex } = tableData;
  table.remove(trIndex);
};

/**
 * 删除列
 * @param {Object} editor 编辑器实例
 */
export const removeCol = (editor) => {
  const tableData = editor.content.getPresentTable();
  if (!tableData) {
    return;
  }
  const { table, tdIndex } = tableData;

  table.trs.forEach((tr) => {
    tr.remove(tdIndex);
  });
};

/**
 * 向右合并单元格
 * @param {Object} editor 编辑器实例
 */
export const mergeToRight = (editor) => {
  const tableData = editor.content.getPresentTable();
  if (!tableData) {
    return;
  }
  const { tdIndex, tdModel, trModel } = tableData;
  if (tdIndex === trModel.tds.length - 1) { // 当前在最后一格
    return;
  }
  tdModel.colspan += 1;
  trModel.remove(tdIndex + 1);
};

/**
 * 向下合并单元格
 * @param {Object} editor 编辑器实例
 */
export const mergeToBottom = (editor) => {
  const tableData = editor.content.getPresentTable();
  if (!tableData) {
    return;
  }
  const { tdIndex, table, tdModel, trIndex } = tableData;
  if (trIndex === table.trs.length - 1) { // 当前在最后一行
    return;
  }
  tdModel.rowspan += 1;
  const nextTr = table.trs[trIndex + 1];
  nextTr.remove(tdIndex);
};

/**
 * 发送替换模版广播
 * @param {Object} editor 编辑器实例
 */
export const replaceModel = (editor) => {
  const modelData = editor.content.getPresentModel();
  if (!modelData) {
    return;
  }
  const { currentTarget } = modelData;
  editor.utils.emitter.emit('replaceModel', true);
  editor.content.setReplaceModelStatus({
    status: true,
    content: currentTarget,
  });
};
