<template>
  <div class="btns-panel">
    <div class="btn-row">
      <el-button :class="{'btn-active': flipVal.includes('rotateY(180deg)')}" @click="setFlip('rotateY(180deg)')">左右翻转</el-button>
      <el-button :class="{'btn-active': flipVal.includes('rotateX(180deg)')}" @click="setFlip('rotateX(180deg)')">上下翻转</el-button>
    </div>
    <div class="btn-row">
      <el-button @click="insertEmptyLine(0)">前插空行</el-button>
      <el-button @click="insertEmptyLine(1)">后查空行</el-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'btnsPanel',
  setup() {
    const IEditor = inject('IEditor');
    const modelData = ref(null);

    const flipVal = ref([]);

    IEditor.utils.emitter.on('menuChange', (data) => {
      const { model } = data;
      modelData.value = model;
      const { transform } = model.style;
      flipVal.value = transform ? transform.split(' ') : [];
    });

    /**
     * 设置翻转方向
     * @param {String} rotateVal 翻转方向
     */
    function setFlip(rotateVal) {
      const index = flipVal.value.indexOf(rotateVal);

      if (index > -1) {
        flipVal.value.splice(index, 1);
      } else {
        flipVal.value.push(rotateVal);
      }
      modelData.value.style.transform = flipVal.value.join(' ');
    }

    /**
     * 插入空行
     * @param {Number} dir 插入空行的方向 0：在前， 1：在后
     */
    function insertEmptyLine(dir) {
      // 这里需要深入探索
      const index = IEditor.content.getMatrixIndex();
      const emptyLineModel = IEditor.model.producer.textModel('<p>&ZeroWidthSpace;</p>');
      console.log('emptyLineModel', emptyLineModel, index.value);

      const newIndex = dir ? index.value + 1 : index.value;
      console.log('newIndex', newIndex);
      IEditor.content.insertCell(emptyLineModel, newIndex, IEditor);
    }

    return {
      flipVal,
      setFlip,
      insertEmptyLine,
    };
  },
});
</script>
<style scoped lang="scss">
.btn-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  &:last-child {
    margin: 0;
  }
  button {
    flex: 1;
  }
}
</style>
