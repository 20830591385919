import { getElemStyle } from '../../../utils/util';
import TextNode from './textNode';

export class TdNode {
  constructor(node) {
    this.menuType = 'td';
    this.colspan = 1;
    this.rowspan = 1;
    this.style = { border: '1px solid #666' };
    this.node = { ...new TextNode(node.firstElementChild) };
  }
}

export class TrNode {
  constructor(node) {
    this.menuType = 'tr';
    this.tds = createTdModels(node);
  }

  push(td) {
    this.tds.push(td);
  }

  remove(index) {
    this.tds.splice(index, 1);
  }

  insert(index, td) {
    this.tds.splice(index, 0, td);
  }
}

function createTdModels(node) {
  const tdElements = node.querySelectorAll('td');
  return [...tdElements].map((td) => new TdNode(td));
}

function createTrModels(node) {
  const trElements = node.querySelectorAll('tr');
  return [...trElements].map((tr) => new TrNode(tr));
}

export default class TableNode {
  constructor(node) {
    this.menuType = 'table';
    this.style = getElemStyle(node);
    this.trs = createTrModels(node);
  }

  push(tr) {
    this.trs.push(tr);
  }

  remove(index) {
    this.trs.splice(index, 1);
  }

  insert(index, tr) {
    const list = [...this.trs];
    list.splice(index, 0, tr);
    this.trs = list;
  }
}
