import { reactive } from 'vue';

const cellGroup = reactive([]);

function getCellGroup() {
  return cellGroup;
}

function getSpecifyCell(index) {
  if (!index && index !== 0) {
    return null;
  }
  return cellGroup[index];
}

function insertCell(content, index, editor) {
  editor.utils.undo.record({
    do: () => {
      if (index || index === 0) {
        cellGroup.splice(index, 0, content);
        return;
      }
      cellGroup.push(content);
    },
    undo: () => {
      const newIndex = cellGroup.indexOf(content);
      if (newIndex === -1) {
        return;
      }
      cellGroup.splice(newIndex, 1);
    },
  });
}

function replaceCell(index, cell, editor) {
  console.log(editor);
  cellGroup.splice(index, 1, cell);
}

function removeCell(index, editor) {
  let cellModel = null;
  editor.utils.undo.record({
    do: () => {
      cellModel = cellGroup.splice(index, 1);
    },
    undo: () => {
      insertCell(cellModel[0], index, editor);
    },
  });
  return cellModel[0];
}

function move(index, dir) {
  const newIndex = dir ? index + 1 : index - 1;
  const cell = cellGroup.splice(index, 1)[0];
  if (!cell) {
    return null;
  }
  cellGroup.splice(newIndex, 0, cell);
  return { cell, newIndex };
}

function moveCell(index, dir, editor) {
  editor.utils.undo.record({
    do: () => {
      const { newIndex } = move(index, dir);
      // console.log('SETnewIndex1', newIndex);
      editor.content.setPresentModelIndex(newIndex);
    },
    undo: () => {
      const undoIndex = dir ? index + 1 : index - 1;
      const { newIndex } = move(undoIndex, Number(!dir));
      editor.content.setPresentModelIndex(newIndex);
    },
  });
}

function clearCell() {
  if (!cellGroup.length) {
    return;
  }
  // while(cellGroup.length > 0) {
  //   cellGroup.pop();
  // }
  cellGroup.splice(0, cellGroup.length);
}

export default {
  getCellGroup,
  getSpecifyCell,
  insertCell,
  replaceCell,
  removeCell,
  moveCell,
  clearCell,
};
