<template>
  <div class="crop-main" v-show="visible">
    <div class="copper-img">
      <img :src="src" ref="corpImgRef">
    </div>
    <div class="crop-btns">
      <button class="crop-btn" @click="setAspectRatio(1)">1 : 1</button>
      <button class="crop-btn" @click="setAspectRatio(4/3)">4 : 3</button>
      <button class="crop-btn" @click="setAspectRatio(16/9)">16 : 9</button>
      <button class="crop-btn" @click="setAspectRatio(NaN)">自动</button>
      <button class="crop-btn" @click="resetCopper">重置</button>
      <button class="crop-btn" @click="cancelCopper">取消</button>
      <button class="crop-btn" type="primary" @click="getCropData">确认</button>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  onMounted,
  ref,
  shallowRef,
  triggerRef,
  watch,
} from 'vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

export default defineComponent({
  name: 'crop',
  props: {
    src: {
      type: String,
      default: 'https://pic1.zhimg.com/v2-938a19a7ad35328c5b68cbbc443545ba_1440w.jpg?source=172ae18b',
    },
  },
  setup(props, ctx) {
    const visible = ref(false);
    const corpImgRef = ref(null);
    const cropper = shallowRef({});

    function setAspectRatio(ratio) {
      cropper.value.setAspectRatio(ratio);
    }

    function resetCopper() {
      cropper.value.reset();
      cropper.value.setAspectRatio(NaN);
    }

    function cancelCopper() {
      // ctx.emit('update', false);
      visible.value = false;
    }

    /**
     * canvas覆盖
     * */
    function canvasCover(canvas, canvasTx, width, height, x, y, img) {
      const dirtyCanvas = document.createElement('canvas');
      const dirtyContext = dirtyCanvas.getContext('2d');
      dirtyCanvas.width = img.width;
      dirtyCanvas.height = img.height;
      dirtyContext.drawImage(img, 0, 0, img.width, img.height);
      const imageData = dirtyContext.getImageData(x, y, img.width, img.height);
      canvasTx.putImageData(imageData, 0, 0, 0, 0, width, height);
      const canvasImg = canvas.toDataURL('image/png', 1);
      ctx.emit('change', canvasImg);
      cancelCopper();
    }

    /**
     * 创建canvas
     * data: 裁剪区域的数据
     * */
    function createCanvas(data) {
      const canvas = document.createElement('canvas');
      const canvasTx = canvas.getContext('2d');
      canvas.width = data.width;
      canvas.height = data.height;
      const img = new Image();
      img.setAttribute('crossOrigin', 'Anonymous');
      img.src = props.src;
      img.onload = () => {
        canvasCover(canvas, canvasTx, data.width, data.height, data.x, data.y, img);
      };
    }

    function getCropData() {
      const data = cropper.value.getData();
      createCanvas(data);
    }

    function cropEndHandle() {
      // console.log('data', cropper.value.getData());
    }

    function initCopper() {
      cropper.value = new Cropper(corpImgRef.value, {
        viewMode: 1,
        dragMode: 'none',
        modal: true,
        toggleDragModeOnDblclick: false,
        cropBoxResizable: true,
        checkCrossOrigin: false,
        autoCropArea: 1,
        rotatable: false,
        ready: cropEndHandle,
        cropend: cropEndHandle,
      });
      triggerRef(cropper);
    }

    watch(() => props.src, (src) => {
      if (src) {
        cropper.value.replace(props.src);
      }
    });

    onMounted(() => {
      visible.value = true;
      initCopper();
    });

    return {
      visible,
      corpImgRef,
      setAspectRatio,
      resetCopper,
      cancelCopper,
      getCropData,
    };
  },
});
</script>
<style lang="scss">
  .crop-main {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .7);
    }
    .copper-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50% !important;
      height: 50% !important;
      transform: translate(-50%, -50%);
    }
    .crop-btns {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translateX(-50%);
      .crop-btn {
        display: inline-block;
        border-radius: 3px;
        padding: 7px 15px;
        margin-left: 10px;
        border: 1px solid #dcdfe6;
        color: #606266;
        font-size: 12px;
        text-align: center;
        box-sizing: border-box;
        background: #fff;
        &[type="primary"] {
          color: #fff;
          background-color: #409eff;
          border-color: #409eff;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
</style>
