import producer from './producer';
import parse, { parseTplStr } from './parse/index';
import nodes from './nodes';
// import node from './nodeModel';

export default {
  producer,
  gen: {},
  parseTpl: parse,
  parseTplStr,
  nodes,
};
