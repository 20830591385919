import { render } from 'vue';
import { parseTplStr } from './parse';
import { TdNode, TrNode } from './nodes/tableNode';

const tdElem = () => (<td i-type="td">
  <div i-type="text" style="text-align: left;">
    <p>&nbsp;</p>
  </div>
</td>);

const trElem = (props) => (<tr i-type="tr">
  {
    console.log('props.col', props.col)
  }
  {
    new Array(props.col).fill(undefined).map(() => <tdElem></tdElem>)
  }
</tr>);

const template = {
  text: (content) => (`<div i-type="node">
    <div i-type="col">
      <div i-type="node">
        <div i-type="text">${content}</div>
      </div>
    </div>
  </div>`),
  image: (src) => (`<div i-type="node">
    <div i-type="col">
      <div i-type="node">
        <div i-type="image">
          <img src="${src}" />
        </div>
      </div>
    </div>
  </div>`),
  table: (row, col) => (<section>
    <div i-type="node">
      <table i-type="table" style="width: 100%; border-collapse: collapse;">
        {
          new Array(row).fill(undefined).map(() => <trElem col={col}></trElem>)
        }
      </table>
    </div>
  </section>),
};

const imageModel = (src) => {
  const imageTemplate = template.image(src);
  return parseTplStr(imageTemplate);
};

const textModel = (content) => {
  const textTemplate = template.text(content);
  return parseTplStr(textTemplate);
};

const tableModel = (trLen, tdLen) => {
  const tableVnode = template.table(trLen, tdLen);
  const container = document.createElement('section');
  render(tableVnode, container);
  return parseTplStr(container);
};

const trModel = (col) => {
  const trVnode = <trElem col={col}></trElem>;
  const container = document.createElement('section');
  render(trVnode, container);
  return new TrNode(container);
};

const tdModel = () => {
  const tdVnode = <tdElem></tdElem>;
  const container = document.createElement('section');
  render(tdVnode, container);
  return new TdNode(container);
};

const listModel = (type, content = '&ZeroWidthSpace;') => ({
  list: {
    type: 'text',
    listType: type,
    style: {
      'list-style-type': 'decimal',
    },
    content,
  },
  data: {
    style: {},
    link: '',
    menuType: 'text',
  },
});

export default {
  imageModel,
  textModel,
  tableModel,
  trModel,
  tdModel,
  listModel,
};
