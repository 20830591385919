import { isMarkVal, toggleVal } from './reaction';

export default {
  underline: {
    icon: 'underline',
    text: '下划线',
    type: 'mark',
    format: 'text-decoration-line',
    value: 'underline',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'text-decoration-line', value: 'underline' }),
  },
  strikethrough: {
    icon: 'strikethrough',
    text: '中划线',
    type: 'mark',
    format: 'text-decoration-line',
    value: 'line-through',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'text-decoration-line', value: 'line-through' }),
  },
  textLeftAlign: {
    icon: 'leftAlign',
    text: '左对齐',
    type: 'mark',
    format: 'textAlign',
    value: 'left',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'textAlign', value: 'left' }),
  },
  textCenterAlign: {
    icon: 'centerAlign',
    text: '居中对齐',
    type: 'mark',
    format: 'textAlign',
    value: 'center',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'textAlign', value: 'center' }),
  },
  textRightAlign: {
    icon: 'rightAlign',
    text: '右对齐',
    type: 'mark',
    format: 'textAlign',
    value: 'right',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'textAlign', value: 'right' }),
  },
  textJustifyAlign: {
    icon: 'justify',
    text: '两端对齐',
    type: 'mark',
    format: 'textAlign',
    value: 'justify',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'textAlign', value: 'justify' }),
  },
  imageLeftAlign: {
    icon: 'leftAlign',
    text: '左对齐',
    type: 'mark',
    format: 'justifyContent',
    value: 'flex-start',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'justifyContent', value: 'flex-start' }),
  },
  imageCenterAlign: {
    icon: 'centerAlign',
    text: '左对齐',
    type: 'mark',
    format: 'justifyContent',
    value: 'center',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'justifyContent', value: 'center' }),
  },
  imageRightAlign: {
    icon: 'rightAlign',
    text: '右对齐',
    type: 'mark',
    format: 'justifyContent',
    value: 'flex-end',
    active: isMarkVal,
    action: (editor) => toggleVal(editor, { format: 'justifyContent', value: 'flex-end' }),
  },
};
