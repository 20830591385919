import { getElemStyle } from '../../../utils/util';
export default class TextNode {
  constructor(node) {
    this.style = getElemStyle(node);
    this.link = '';
    this.menuType = 'text';
    this.setModelContent(node);
  }

  setModelContent(node) {
    const child = node.firstElementChild;
    if (child.nodeName !== 'P') {
      return;
    }
    this.content = node.innerHTML;
  }
}
