<template>
  <div class="cell-locate" :style="style" v-if="visible">
    <p>请选择模版</p>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, inject } from 'vue';
import { isInEditor } from '../../../utils/check';

export default defineComponent({
  setup() {
    const IEditor = inject('IEditor');
    const visible = ref(false);
    const style = reactive({});

    IEditor.utils.emitter.on('replaceModel', (data) => {
      visible.value = data;
      if (!data) {
        return;
      }
      const { currentTarget } = IEditor.content.getPresentModel();

      const nodeElem = currentTarget.parentElement;
      const { clientWidth, clientHeight, offsetTop, offsetLeft } = nodeElem;
      style.width = `${clientWidth}px`;
      style.height = `${clientHeight}px`;
      style.top = `${offsetTop}px`;
      style.left = `${offsetLeft}px`;
    });

    onMounted(() => {
      document.addEventListener('click', (event) => {
        const { target } = event;

        if (!isInEditor(target, IEditor)) {
          visible.value = false;
        }
      }, false);
    });

    return {
      visible,
      style,
    };
  },
});
</script>
<style scoped lang="scss">
.cell-locate {
  position: absolute;
  outline: 1px #ff3802 dashed;
  background: rgba(255,255,255,.7);
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
