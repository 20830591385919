import crop from '../../../../viewPort/crop/index.vue';
// import crop from '../../../../../../crop/index.vue';
import Popup from './popup';

function setCropOpts(editor) {
  const { model: cellModel } = editor.content.getPresentModel();
  console.log('cellModel', cellModel);
  if (!cellModel.src) {
    throw new Error('image数据模型出错');
  }
  return {
    component: crop,
    src: cellModel.src,
    onChange: function callback(newSrc) {
      console.log('onChange', this);
      const oldVal = cellModel.src;
      editor.utils.undo.record({
        do: () => {
          cellModel.src = newSrc;
        },
        undo: () => {
          cellModel.src = oldVal;
        },
      });
      // this.visible = false;
    },
  };
}

function chooseComponent(editor, comNmae) {
  switch (comNmae) {
    case 'crop':
      return setCropOpts(editor);
    default:
      return null;
  }
}

export function togglePopup(editor, comName) {
  const opts = chooseComponent(editor, comName);
  Popup(opts);
}

export const text = null;
