import { createVNode, render } from 'vue';
// import crop from '../../../../../crop/index.vue';

export default function Popup(opts) {
  const componentElem = opts.component;
  delete opts.component;
  const options = opts;

  const container = document.createElement('div');

  const vm = createVNode(
    componentElem,
    options,
  );
  vm.props.onDestroy = () => {
    render(null, container);
  };

  render(vm, container);
  document.body.appendChild(container.firstElementChild);
}
