import { defineComponent, inject } from 'vue';
import { markButton, selectButton, colorButton, blockButton } from './menuItem';

export default defineComponent({
  components: {
    markButton,
    selectButton,
    colorButton,
    blockButton,
  },
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    className: String,
  },
  setup(props) {
    const IEditor = inject('IEditor');
    // console.log('props.menus', props.menus);
    return () => (<div className={props.className ? props.className : 'editor-menu'}>
      {
        props.menus.map((prop) => {
          // console.log('prop', prop);
          switch (prop && prop.type) {
            case 'mark':
              return <mark-button editor={IEditor} menu={prop}></mark-button>;
            case 'select':
              return <select-button editor={IEditor} menu={prop}></select-button>;
            case 'color':
              return <color-button editor={IEditor} menu={prop}></color-button>;
            case 'block':
              return <block-button editor={IEditor} menu={prop}></block-button>;
            default:
              return null;
          }
        })
      }
    </div>);
  },
});
