import * as clipboard from 'clipboard-polyfill';
import domUtil from './dom';
import UndoManager from './undo';
import uploadFile from './uploadFile';
import Emitter from './emitter';
// import selection from './selection';

// 全选编辑器内容
function selectAllContent(editorViewElem) {
  const selection = window.getSelection();
  selection.removeAllRanges();
  const range = document.createRange();
  range.setStartBefore(editorViewElem.firstElementChild);
  range.setEndAfter(editorViewElem.lastElementChild);
  selection.addRange(range);
  selection.selectAllChildren(editorViewElem);
}
export default class Utils {
  constructor(editor, config) {
    this.dom = domUtil;
    this.undo = new UndoManager(editor);
    this.uploadFile = uploadFile(config.upload || {});
    this.emitter = new Emitter();
  }

  async copyFull() {
    const editorViewElem = document.querySelector('.editor-view');
    selectAllContent(editorViewElem);
  }

  getTextModel(model) {
    const models = Array.isArray(model) ? model : new Array(model);
    const findText = models.find((modelItem) => modelItem && modelItem.menuType === 'text');
    if (findText) {
      return findText;
    }
    for (let i = 0; i < models.length; i += 1) {
      if (models[i]) {
        return this.getTextModel(models[i].colList || models[i].nodes);
      }
    }
  }
}
