/* 需要通过select组件进行选择的menu */
import { FONT_FAMILY_ENUMS, FONT_SIZE_ENUMS, D_FONT_SIZE } from './default';
import { getSelectVal, toggleSelect } from './reaction';
// import { getSelectVal } from './reaction';

export default {
  fontSize: {
    text: '字号',
    icon: '',
    type: 'select',
    format: 'fontSize',
    options: FONT_SIZE_ENUMS,
    defaultVal: D_FONT_SIZE.defaultVal,
    value: getSelectVal,
    active: false,
    action: (editor, menu, val) => toggleSelect(editor, menu, val),
  },
  fontFamily: {
    text: '字体',
    icon: '',
    type: 'select',
    format: 'fontFamily',
    options: FONT_FAMILY_ENUMS,
    value: getSelectVal,
    active: false,
    action: (editor, menu) => toggleSelect(editor, menu),
  },
};
