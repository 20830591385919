export const getSelectVal = (editor, menu) => {
  const { format, defaultVal } = menu;
  const modelData = editor.content.getPresentModel();
  if (!modelData) {
    return false;
  }
  const { model, anchorEvent } = modelData;
  const target = anchorEvent && anchorEvent.target;
  if (target && target.nodeName === 'SPAN') {
    // 获取target的应用样式
    const style = window.getComputedStyle(target);
    const value = format === 'fontSize' ? Number.parseInt(style[format], 10) : style[format];
    return value || defaultVal;
  }
  if (!model) {
    return defaultVal;
  }
  const value = format === 'fontSize' ? Number.parseInt(model.style[format], 10) : model.style[format];
  return value || defaultVal;
};

export const toggleSelect = (editor, menu, val) => {
  const { format } = menu;
  const { model } = editor.content.getPresentModel();
  const range = editor.selection.getAnchorRange();
  const newVal = typeof val === 'number' ? `${val}px` : val;
  if (!range || range.collapsed) {
    const oldVal = model.style[format];
    editor.utils.undo.record({
      do: () => {
        model.style[format] = newVal;
      },
      undo: () => {
        model.style[format] = oldVal;
      },
    });
  } else {
    // console.log('format', format, newVal);
    editor.selection.resetRange(range);
    // editor.utils.undo.record({
    //   isDoc: true,
    // });
    // document.execCommand('styleWithCSS', null, true);
    // document.execCommand(format, false, newVal);
    const selection = editor.selection.getSelection();
    editor.selection.updateContentStyle(selection, editor, { format, value: newVal });
  }
};
