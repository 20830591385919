import ISelect from './select';
import IPanel from './panel';
// import IInput from './input';
import IColorPicker from './colorPicker';
// import IMessage from './message';

const DomComponents = [ISelect, IPanel, IColorPicker];
const plugins = [
  // IMessage,
];

const install = (app) => {
  // 注册Dom组件
  DomComponents.forEach((component) => {
    app.component(component.name, component);
  });

  // 注册方法组件
  plugins.forEach((plugin) => {
    app.use(plugin);
  });
};

export {
  ISelect,
  IPanel,
  IColorPicker,
  // IMessage,
};

export default {
  install,
};
