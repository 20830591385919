import {
  onMounted,
  reactive,
  ref,
  watchEffect,
} from 'vue';

function getSelected(list, props, value, isDefault) {
  const { valueKey } = props;
  const newVal = value && typeof value === 'object' ? value.value : value;
  if (newVal === null && isDefault) {
    return list[0];
  }
  return list.find((item) => item[valueKey] === newVal);
}
export default (props, ctx) => {
  const visible = ref(false);
  const selected = ref(null);
  const customStyle = reactive({});
  watchEffect(() => {
    selected.value = getSelected(props.list, props.props, props.value, props.isDefault);
  });

  function setDropdownPos(elem) {
    const { width, left, bottom } = elem.getBoundingClientRect();
    customStyle.top = `${bottom + 10}px`;
    customStyle.left = `${left}px`;
    customStyle.width = `${width}px`;
  }

  function showDropdown(e) {
    console.log(e, e.currentTarget);
    setDropdownPos(e.currentTarget);
    visible.value = true;
  }

  function closeDropdown() {
    visible.value = false;
  }

  function emitSelectChange(value) {
    ctx.emit('change', value);
  }

  function clickHandle(val) {
    if (props.value === null) {
      selected.value = getSelected(props.list, props.props, val, props.isDefault);
    }
    emitSelectChange(val);
    closeDropdown(); // 选择后关闭
  }

  onMounted(() => {});
  return {
    visible,
    customStyle,
    selected,
    showDropdown,
    closeDropdown,
    emitSelectChange,
    clickHandle,
  };
};
