<template>
  <div class="complex-toolbar">
    <editor-menu :menus="menus" class-name="toolbar-menu"></editor-menu>
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue';
import editorMenu from '../public/menu/menu';

export default defineComponent({
  name: 'complexToolbar',
  components: {
    editorMenu,
  },
  setup() {
    const IEditor = inject('IEditor');
    const menus = IEditor.menus.filterMenus(['replaceModel']);

    return {
      menus,
    };
  },
});
</script>
