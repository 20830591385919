<template>
  <div class="editor-wrapper" ref="editorWrap">
    <div class="editor-wrap-panel">
      <div class="editor-body" ref="editorBodyElem">
        <head-tool-bar></head-tool-bar>
        <editable></editable>
        <toolbar></toolbar>
      </div>
    </div>
    <div ref="controlPanelElem">
       <control-panel></control-panel>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, onMounted, ref } from 'vue';
import headToolBar from '../headToolBar/headToolBar.vue';
import editable from './editable.vue';
import controlPanel from '../controlPanel/controlPanel.vue';
import toolbar from '../toolBar/toolbar.vue';

export default defineComponent({
  name: 'IEditorWrap',
  components: {
    headToolBar,
    editable,
    controlPanel,
    toolbar,
  },
  setup() {
    const editorWrap = ref(null);
    const editorBodyElem = ref(null);
    const controlPanelElem = ref(null);
    const IEditor = inject('IEditor');
    // console.log('ctx', IEditor);

    onMounted(() => {
      IEditor.setup({
        contentElm: editorWrap.value,
        controlPanelElem: controlPanelElem.value,
        editorBodyElem: editorBodyElem.value,
      });
    });

    return {
      editorWrap,
      editorBodyElem,
      controlPanelElem,
    };
  },
});
</script>
<style scope lang="scss">
.editor-wrapper {
  display: flex;
  height: 100%;
  .editor-wrap-panel {
    flex: 1;
    .editor-body {
      position: static;
      width: 500px;
      margin: 0 auto;
    }
  }
}
</style>
