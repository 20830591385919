let fileInputElem = null;

function isFun(val) {
  return val && typeof val === 'function';
}

function createInputElem(accept) {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = accept;
  fileInput.style.position = 'fixed';
  fileInput.style.left = '0';
  fileInput.style.top = '0';
  fileInput.style.opacity = '0.001';
  document.body.appendChild(fileInput);
  return fileInput;
}

function cancelHandler() {
  fileInputElem.parentNode.removeChild(fileInputElem);
}

function pickFile(accept) {
  return new Promise((resolve) => {
    const fileInput = createInputElem(accept);
    fileInputElem = fileInput;
    fileInput.addEventListener('change', (e) => {
      resolve(Array.prototype.slice.call(e.target.files));
    });
    fileInput.click();
  });
}

function fileToUrl(file) {
  return window.URL.createObjectURL(file);
}

export async function getImageUrl() {
  const files = await pickFile();
  if (files.length > 0) {
    const file = files[0];
    return fileToUrl(file);
  }
  return null;
}

export default function uploadFile(uploadConfig) {
  const { customUpload, beforUpload, limit, onExceed } = uploadConfig;
  return async (accept = 'image/*', isUrl = true) => {
    const files = await pickFile(accept);
    if (limit && files.length > limit && isFun(onExceed)) {
      // 超出最大范围
      onExceed();
    }
    if (isFun(customUpload)) {
      return customUpload(files);
    }
    if (isFun(beforUpload)) {
      beforUpload(files);
    }
    if (files.length > 0) {
      const file = files[0];
      if (!isUrl) {
        return file;
      }
      const url = fileToUrl(file);
      cancelHandler(); // 文件上传完成，删除input

      return url;
    }
    return null;
  };
}
