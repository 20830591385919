<template>
  <div class="complex-panel">
    <div class="panel-block">
      <div class="row-chunk">
        <span class="row-title">旋转</span>
        <el-input v-model="rotateDeg" placeholder="按回车键确认链接" @change="setRotateDeg"></el-input>
      </div>
      <div class="row-chunk">
        <span class="row-title">透明</span>
        <el-input v-model="opacityVal" placeholder="按回车键确认链接" @change="setOpacity"></el-input>
      </div>
    </div>
    <div class="panel-block">
      <el-collapse accordion>
        <el-collapse-item title="阴影" name="1">
          <div class="row-chunk">
            <span class="row-title">宽度</span>
            <el-input v-model="boxShadowVal.width" type="number" placeholder="按回车键设置阴影宽度" :min="0" :max="100" @change="setBoxShadow">
              <template #suffix>
                <span>像素</span>
              </template>
            </el-input>
          </div>
          <div class="row-chunk">
            <span class="row-title">颜色</span>
            <el-color-picker v-model="boxShadowVal.color" @change="setBoxShadow"></el-color-picker>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="panel-block" v-show="colData">
      <el-button type="danger" class="block-btn" @click="removeCurrentModel">删除操作块</el-button>
    </div>
    <div class="panel-block">
      <el-button type="danger" class="block-btn" @click="removeCurrentCell">删除模版</el-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, inject } from 'vue';

export default defineComponent({
  name: 'complexPanel',
  setup() {
    const IEditor = inject('IEditor');
    const modelData = ref(null);
    const nodeModelData = ref(null);
    const nodeIndexData = ref(null);
    const colData = ref(null);

    const rotateDeg = ref(null);
    const opacityVal = ref(null);
    const boxShadowVal = reactive({});

    IEditor.utils.emitter.on('menuChange', (data) => {
      const { model, nodeModel, col, nodeIndex } = data;
      const { opacity, boxShadow } = model.style;
      rotateDeg.value = null;
      opacityVal.value = !opacity && opacity !== 0 ? 100 : opacity * 100;
      if (boxShadow && boxShadow !== 'none') {
        const [x, y, width, color] = boxShadow.split(' ');
        console.log(x, y);
        boxShadowVal.width = width ? Number.parseInt(width, 10) : null;
        boxShadowVal.color = color || null;
      }
      // console.log('col', col);
      // console.log('nodeIndex', nodeIndex);
      modelData.value = model;
      nodeModelData.value = nodeModel.data;
      nodeIndexData.value = nodeIndex;
      colData.value = col;
    });

    /**
     * 设置旋转角度
     */
    function setRotateDeg() {
      let rotateVal = rotateDeg.value;
      if (rotateVal < 0) {
        rotateVal = 0;
      } else if (rotateVal > 360) {
        rotateVal = 360;
      }
      const oldVal = nodeModelData.value.style.transform;
      IEditor.utils.undo.record({
        do: () => {
          nodeModelData.value.style.transform = `rotateZ(${rotateVal}deg)`;
        },
        undo: () => {
          nodeModelData.value.style.transform = oldVal;
        },
      });
    }

    /**
     * 设置透明度
     * @param {Number} val 透明度
     */
    function setOpacity(val) {
      const oldVal = modelData.value.style.opacity;
      IEditor.utils.undo.record({
        do: () => {
          modelData.value.style.opacity = val / 100;
        },
        undo: () => {
          modelData.value.style.opacity = oldVal;
        },
      });
    }

    /**
     * 设置阴影
     */
    function setBoxShadow() {
      const oldVal = modelData.value.style.boxShadow;
      IEditor.utils.undo.record({
        do: () => {
          modelData.value.style.boxShadow = `${boxShadowVal ? `0px 0px ${boxShadowVal.width}px ${boxShadowVal.color || '#000000'}` : ''}`;
        },
        undo: () => {
          modelData.value.style.boxShadow = oldVal;
        },
      });
    }

    /**
     * 删除当前操作块
     */
    function removeCurrentModel() {
      // 此处删除一个node，但是页面会保留一个col元素，之后考虑删除
      if (!colData.value || nodeIndexData.value) {
        return;
      }
      const index = nodeIndexData.value;
      let node = null;
      IEditor.utils.undo.record({
        do: () => {
          node = colData.value.remove(index);
        },
        undo: () => {
          colData.value.insert(index, node);
        },
      });
    }

    /**
     * 删除整个模板
     */
    function removeCurrentCell() {
      const modelIndex = IEditor.content.getMatrixIndex();
      IEditor.content.removeCell(modelIndex.value, IEditor);
    }

    return {
      colData,
      rotateDeg,
      opacityVal,
      boxShadowVal,
      // nodeModel,
      setRotateDeg,
      setOpacity,
      setBoxShadow,
      removeCurrentModel,
      removeCurrentCell,
    };
  },
});
</script>
<style scoped lang="scss">
.btn-active {
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
}
</style>
