import { getElemStyle } from '../../../utils/util';

export default class ImageNode {
  constructor(node) {
    this.style = getElemStyle(node);
    this.link = '';
    this.menuType = 'image';
    this.setModelSrc(node);
  }

  setModelSrc(node) {
    const child = node.firstElementChild;
    if (child.nodeName !== 'IMG') {
      return;
    }
    this.src = child.getAttribute('src');
  }
}
