<template>
  <div class="head-tool-bar" id="head-tool-bar">
    <editor-menu :menus="menus"></editor-menu>
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue';
import editorMenu from '../public/menu/menu';

export default defineComponent({
  components: {
    editorMenu,
  },
  setup() {
    const IEditor = inject('IEditor');

    const menus = IEditor.menus.filterMenus([
      'fontSize', 'fontFamily', 'bold', 'italic',
      'underline', 'strikethrough', 'fontColor',
      'bgColor', 'undo', 'redo', 'insertOl',
      'insertUl', 'insertTable',
    ]);
    // console.log('new ctx', ctx.attrs, menus);

    return {
      menus,
    };
  },
});
</script>
<style scoped lang="scss">
.head-tool-bar {
  width: 100%;
  padding: 10px 5px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  background: #fff;
  z-index: 1;
}
</style>
