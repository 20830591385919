import { isElement } from './check';

/**
 * 烤串命名转驼峰命名
 * @param {String} str
 * @returns { String }
 */
export function kebabToCamel(str) {
  return str.replace(/-./g, (m) => m.toUpperCase()[1]);
}

/**
 * 获取指定元素的style对象
 * @param {Element} elem 元素
 * @returns style
 */
export function getElemStyle(elem) {
  function getStyleObj(cssText) {
    const styleArr = cssText.split(';');
    const styleObj = {};
    styleArr.forEach((style) => {
      if (style) {
        const [key, value] = style.split(':');
        const camelKey = kebabToCamel(key).trim();
        styleObj[camelKey] = value.trim();
      }
    });
    return styleObj;
  }
  return elem && isElement(elem) ? getStyleObj(elem.style.cssText) : {};
}

/**
 * HTML字符串转DOM
 * @param {String} html
 * @returns DOM
 */
export function domParse(html) {
  return new DOMParser().parseFromString(html, 'text/html').body;
}

/**
 * 获取url的query参数
 */
export function getQueryString(name) {
  const params  = new URLSearchParams(window.location.search);
  return params.get(name);
}

/**
 * 序列化对象
 */
export function serialize(obj, name){
  let result = "";
  function serializeInternal(o, path) {
    for (let p in o) {
      const value = o[p];
      if (typeof value != "object") {
        if (typeof value == "string") {
          result += "\n" + path + "[" + (isNaN(p)?"\""+p+"\"":p) + "] = " + "\"" + value.replace(/\"/g,"\\\"") + "\""+";";
        }else {
          result += "\n" + path + "[" + (isNaN(p)?"\""+p+"\"":p) + "] = " + value+";";
        }
      }
      else {
        if (value instanceof Array) {
          result += "\n" + path +"[" + (isNaN(p)?"\""+p+"\"":p) + "]"+"="+"new Array();";
          serializeInternal(value, path + "[" + (isNaN(p)?"\""+p+"\"":p) + "]");
        } else {
          result += "\n" + path  + "[" + (isNaN(p)?"\""+p+"\"":p) + "]"+"="+"new Object();";
          serializeInternal(value, path +"[" + (isNaN(p)?"\""+p+"\"":p) + "]");
        }
      }
    }
  }
  serializeInternal(obj, name);
  return result;
}
