import { defineComponent } from 'vue';
import textRender from './textRender';
import imageRender from './imageRender';
import tableRender from './tableRender';

function chooseRender(model, col, nodeIndex) {
  switch (model.menuType) {
    case 'colList':
      return <colRender colList={model.colList}></colRender>;
    case 'text':
      return <textRender model={model} col={col} nodeIndex={nodeIndex}></textRender>;
    case 'image':
      return <imageRender model={model} col={col} nodeIndex={nodeIndex}></imageRender>;
    case 'table':
      return <tableRender model={model} col={col} nodeIndex={nodeIndex}></tableRender>;
    default:
      return null;
  }
}

const colRender = defineComponent({
  name: 'colRender',
  props: {
    colList: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    return () => (<>
      {
        props.colList.map((col) => (<section class="col" style={col.style}>
          {
            col.nodes && col.nodes.map((node, index) => <nodeRender model={node} col={col} nodeIndex={index}></nodeRender>)
          }
        </section>))
      }
    </>);
  },
});

const nodeRender = defineComponent({
  name: 'nodeRender',
  props: {
    model: {
      type: Object,
      required: true,
    },
    col: {
      type: Object,
    },
    nodeIndex: Number,
  },
  setup(props) {
    return () => (<>
      <section
       class="node-scope"
        style={props.model.data.style}
      >
        {
          chooseRender(props.model, props.col, props.nodeIndex)
        }
      </section>
    </>);
  },
});

export default nodeRender;
