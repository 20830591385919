<template>
  <div class="editor-content">
    <div
      id="editor-view"
      class="editor-view"
      :style="{'margin-bottom': `${cellGroup.length ? 5 : ''}px`}"
    >
      <template v-for="(cell, index) in cellGroup" :key="index">
        <!-- start traverse render cell -->
        <div>
          <move-cell :index="index" :total="cellGroup.length"></move-cell>
          <render-model :matrix="cell" :index="index"></render-model>
          <cell-locate></cell-locate>
        </div>
        <!-- end traverse render cell -->
      </template>
    </div>
    <default-write></default-write>
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue';
import DefaultWrite from './defaultWrite.vue';
import renderModel from './renderModel/renderModel';
import moveCell from '../public/moveCell/moveCell.vue';
import cellLocate from '../public/cellLocate/cellLocate.vue';

export default defineComponent({
  components: {
    DefaultWrite,
    renderModel,
    moveCell,
    cellLocate,
    // renderCom,
  },
  setup() {
    const IEditor = inject('IEditor');

    const cellGroup = IEditor.content.getCellGroup();

    function onProjectionCopy(event) {
      console.log('event', event);
      console.log('getData', event.clipboardData.getData('text/html'));
      event.clipboardData.setData('text/html', '<p>112233</p>');
      event.clipboardData.setData('text/plain', '<p>112233</p>');
    }

    function onProjectionPaste(event) {
      console.log('onProjectionPaste', event);
      console.log('onProjectionPaste', event.clipboardData.getData('text/html'));
    }

    return {
      cellGroup,
      onProjectionCopy,
      onProjectionPaste,
    };
  },
});
</script>
<style scoped lang="scss">
.editor-content {
  // width: 500px;
  max-height: calc(100% - 80px);
  padding: 12px 10px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.6;
  background: #fff;
  text-align: left;
}
</style>
