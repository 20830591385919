<template>
  <div v-if="isLoad" class="loading">
    <i class="el-icon-loading icon"></i>
  </div>
  <editor-main v-else :IEditor="IEditorInstance">
    <!-- <tpl-library></tpl-library> -->
    <editor-warp></editor-warp>
  </editor-main>
</template>
<script>
import { onMounted, ref } from 'vue';
import createEditor from './core/index';
import IEditor from './core/editor.vue';
import tplLibrary from './viewPort/tplLibrary/tplLibrary.vue';
import editorWarp from './viewPort/editorWarp/editorWarp.vue';

export default {
  name: 'IEditor',
  components: {
    editorMain: IEditor,
    tplLibrary,
    editorWarp,
  },
  props: {
    apiKey: String,
    config: Object,
  },
  setup() {
    const isLoad = ref(true);
    const IEditorInstance = ref({});

    onMounted(() => {
      IEditorInstance.value = createEditor();
      IEditorInstance.value.message.listenType('init', (apiKey) => {
        console.log('apiKey', apiKey);
        return IEditorInstance.value;
      });
      isLoad.value = false;
    });

    return {
      isLoad,
      IEditorInstance,
    };
  },
};
</script>
<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
  }
}
</style>
