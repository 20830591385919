import { ref } from 'vue';
import throttle from 'lodash/throttle';

export const IEditor = ref(null);

function setMenuType(type) {
  if (!type) {
    return;
  }
  IEditor.value.content.setMenuType(type);
}

export function clickCellHandle(event, model, node) {
  // event.stopPropagation();
  console.log(1111111, model, node);
  const { currentTarget } = event;
  setMenuType(model.menuType || model.type);
  IEditor.value.content.setPresentModel(model);
  IEditor.value.content.setAnchorEvent(event);
  IEditor.value.content.setCurrentTarget(currentTarget);
  IEditor.value.content.setPresentNodeModel(node.data);
}

export const textCellBlurHandle = throttle((event, model) => {
  const innerHtml = event.target.innerHTML;
  const oldContent = model.content;
  IEditor.value.utils.undo.record({
    do: () => {
      model.content = innerHtml;
    },
    undo: () => {
      model.content = oldContent;
    },
  });
}, 1000, {
  leading: false,
  trailing: true,
});

export function clickNodeHandle(event, props) {
  // 为了防止当前node被上层清理，所以，此处阻断事件传播
  // 考虑采用emit向上级传递事件
  event.stopPropagation();
  console.log(2222, event, props);
  const model = props.node.data;
  // console.log('model', model);
  setMenuType(model.menuType || model.type);

  // 模板最外层没有col
  IEditor.value.content.setPresentNode(props.col && {
    node: props.node,
    col: props.col,
    nodeIndex: props.nodeIndex,
  });
}

export function tdHandle(td, index) {
  IEditor.value.content.setPresentTable('currentTd', td);
  IEditor.value.content.setPresentTable('tdIndex', index);
}

export function trHandle(tr, index) {
  IEditor.value.content.setPresentTable('currentTr', tr);
  IEditor.value.content.setPresentTable('trIndex', index);
}

export function tableHandle(table) {
  // console.log(333);
  IEditor.value.content.setPresentTable('currentTable', table);
}
