export default [
  {
    id: 1,
    template: `<section class="cmp-scope">
      <section style="width: 100%;">
        <section style="
          box-sizing: border-box;
          padding: 1.5rem;
          border-left: 8px solid #19570a;
          border-right: 8px solid #19570a;">
          <section style="
            font-size:0.875rem;
            line-height: 1.5rem;
            letter-spacing: 1px;">
            <p>
              1111对自己好一点。对别人好一点。善待自己，善待坚强乐观的自己。不再为不在乎我的人掉眼泪。选择相信对方，不管他是好是坏。
              做一个聪明的女子，只在调皮时装傻。认真地生活。少一点幻想。不再期待幸福的降临，它就在身边。我想要，活得比夏天还要温暖。
            </p>
          </section>
        </section>
      </section>
    </section>`,
    matrix: {
      colList: [
        {
          style: {},
          nodes: [
            {
              colList: [
                {
                  nodes: [
                    {
                      text: {
                        style: {
                          fontsize: '0.875rem',
                          lineHeight: '1.5rem',
                          letterSpacing: '1px',
                        },
                        link: '',
                        type: 'text',
                        editable: true,
                        content: `<p>
                          1111对自己好一点。对别人好一点。善待自己，善待坚强乐观的自己。不再为不在乎我的人掉眼泪。选择相信对方，不管他是好是坏。
                          做一个聪明的女子，只在调皮时装傻。认真地生活。少一点幻想。不再期待幸福的降临，它就在身边。我想要，活得比夏天还要温暖。
                        </p>`,
                      },
                      data: {
                        style: {},
                        menuType: 'text',
                      },
                    },
                  ],
                  style: {
                    padding: '0 1.5rem',
                  },
                },
              ],
              data: {
                style: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  boxSizing: 'border-box',
                  padding: '1.5rem',
                  borderLeftWidth: '8px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: '#19570a',
                  borderRightWidth: '8px',
                  borderRightStyle: 'solid',
                  borderRightColor: '#19570a',
                },
                colorTheme: {
                  borderLeftColor: '#19570a',
                  borderRightColor: '#19570a',
                },
              },
            },
          ],
        },
      ],
      data: {
        style: {
          width: '100%',
        },
      },
    },
  },
  {
    id: 2,
    template: `<section class="cmp-scope">
      <section style="border: dashed 1px #797979; padding: 5px;">
        <section style="box-sizing: border-box;margin: 0.5rem auto;">
          <section style="box-shadow:0 0 3px #e1e1e1;border: 1px solid #e1e1e1">
            <section style="padding-left: 1rem;">
              <section style="
                border-left: 1px solid #ec421e;
                padding: 1rem;
                box-sizing: border-box;
                letter-spacing: 4px;
                text-align: justify;
                font-size: 0.875rem">
                  <p>
                    于千万人之中遇见你所要遇见的人，于千万年之中，时间的无涯的荒野里，没有早一步，也没有晚一步，刚巧赶上了，那也没有别的话可说，惟有轻轻地问一声：“噢，你也在这里吗？”
                  </p>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>`,
    matrix: {
      colList: [
        {
          style: {
            margin: '0.5rem auto',
          },
          nodes: [
            {
              colList: [
                {
                  style: {
                    paddingLeft: '1rem',
                  },
                  nodes: [
                    {
                      text: {
                        style: {
                          borderLeftWidth: '1px',
                          borderLeftStyle: 'solid',
                          borderLeftColor: '#ec421e',
                          padding: '1rem',
                          boxSizing: 'border-box',
                          letterSpacing: '4px',
                          textAlign: 'justify',
                          fontSize: '16px',
                        },
                        type: 'text',
                        link: '',
                        editable: true,
                        content: `<p>
                          于千万人之中遇见你所要遇见的人，于千万年之中，时间的无涯的荒野里，没有早一步，也没有晚一步，刚巧赶上了，那也没有别的话可说，惟有轻轻地问一声：“噢，你也在这里吗？”
                        </p>`,
                      },
                      data: {
                        menuType: 'text',
                      },
                    },
                  ],
                },
              ],
              data: {
                style: {
                  boxShadow: '0 0 3px #e1e1e1',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: '#e1e1e1',
                },
              },
            },
          ],
        },
      ],
      data: {
        style: {
          borderStyle: 'dashed',
          borderWidth: '1px',
          borderColor: '#797979',
          padding: '5px',
          boxSizing: 'border-box',
        },
      },
    },
  },
  {
    id: 3,
    template: `<section class="cmp-scope">
      <section style="
        margin: 15px;
        white-space: normal;
        border-top: 1px solid rgb(152, 34, 8);
        border-bottom: 1px solid rgb(152, 34, 8);box-sizing: border-box;">
        <section style="font-size:40px;height:8px;margin-left:35%;width:65%;
        background-color:#fefefe;margin-top:-1px;line-height: 10px;">
          <span style="color: #9ba7b5;">“</span>
        </section>
        <section style="margin: 5px 15px 20px;text-align:justify;line-height: 1.75em;
        color: #595959;font-size: 14px;letter-spacing: 1.5px;">
          <p>
            当清晨的一缕阳光透过窗帘上的空隙映照在沉睡的脸庞时，微微张开的双眼朦胧地注视着周遭的一切，新的一天悄然而至。
          </p>
        </section>
        <section style="
          font-size: 40px;
          background-color: #fefefe;
          text-align: right;
          height: 10px;
          margin-bottom: -8px;width: 65%;line-height: 10px;">
          <span style="color: #9ba7b5;">”</span>
        </section>
      </section>
    </section>`,
    matrix: {
      colList: [
        {
          style: {},
          nodes: [
            {
              text: {
                style: {
                  fontSize: '40px',
                  height: '8px',
                  marginLeft: '35%',
                  width: '65%',
                  backgroundColor: '#fefefe',
                  marginTop: '-1px',
                  lineHeight: '10px',
                },
                editable: false,
                content: '<span style="color: #9ba7b5;">“</span>',
              },
              data: {},
            },
            {
              text: {
                style: {
                  margin: '5px 15px 20px',
                  textAlign: 'justify',
                  lineHeight: '1.75em',
                  color: '#595959',
                  fontSize: '14px',
                  letterSpacing: '1.5px',
                },
                editable: true,
                link: '',
                content: '<p>当清晨的一缕阳光透过窗帘上的空隙映照在沉睡的脸庞时，微微张开的双眼朦胧地注视着周遭的一切，新的一天悄然而至。</p>',
              },
              data: {
                menuType: 'text',
              },
            },
            {
              text: {
                style: {
                  fontSize: '40px',
                  height: '10px',
                  textAlign: 'right',
                  width: '65%',
                  backgroundColor: '#fefefe',
                  marginBottom: '-8px',
                  lineHeight: '10px',
                },
                type: 'text',
                editable: false,
                content: '<span style="color: #9ba7b5;">”</span>',
              },
              data: {},
            },
          ],
        },
      ],
      data: {
        style: {
          margin: '15px',
          whiteSpace: 'normal',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'rgb(152, 34, 8)',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'rgb(152, 34, 8)',
        },
      },
    },
  },
];
