import { isFunction } from '../../utils/check';
import { listenTypes } from './listen';

export default {
  // 初始化编辑器
  init: (message, messageInstance) => {
    const { apiKey } = message;
    const callback = listenTypes['init'];
    if (!isFunction(callback)) {
      return;
    }
    const instance = callback(apiKey);
    messageInstance.IEditorAPI = instance;
    messageInstance.pushMessage('init');
  },

  // 解析模板文件
  parseFile: async (message, messageInstance) => {
    // console.log('parseFile');
    const callback = listenTypes['parseFile'];
    if (!isFunction(callback)) {
      return;
    }
    const tplData = await callback();
    messageInstance.pushMessage('parseFile', tplData);
  },

  // 设置编辑器内容(会清空已有的内容)
  setContent: (message, messageInstance) => {
    const { matrix } = message;
    if (!matrix) {
      throw new Error('IEditor不允许插入空内容');
    }
    const IEditor = messageInstance.IEditorAPI;
    IEditor.content.clearCell();
    IEditor.content.insertCell(matrix, null, IEditor);
  },

  // 获取编辑器全部内容
  getContent: (message, messageInstance) => {
    const callback = listenTypes['getContent'];
    if (!isFunction(callback)) {
      return;
    }
    const content = callback();
    messageInstance.pushMessage('getContent', content);
  },

  // 复制全文
  copyFull: (message, messageInstance) => {
    const IEditor = messageInstance.IEditorAPI;
    IEditor.utils.copyFull();
  },

  // 向编辑器插入内容
  insertContent(message, messageInstance) {
    const { content } = message;
    if (!content) {
      throw new Error('IEditor不允许插入空内容');
    }
    const IEditor = messageInstance.IEditorAPI;
    const matrix = IEditor.model.parseTplStr(content); // 将html解析成矩阵
    const replaceModelData = IEditor.content.getReplaceModelStatus();

    if (replaceModelData && replaceModelData.content) {
      const textModel = IEditor.utils.getTextModel(matrix);
      if (textModel && replaceModelData.content) {
        textModel.text.setModelContent(replaceModelData.content);
      }
      const modelData = IEditor.content.getPresentModel();
      const { col, nodeIndex } = modelData;
      col.replace(nodeIndex, matrix);
      IEditor.content.setReplaceModelStatus(null);
      return;
    }
    IEditor.content.insertCell(matrix, null, IEditor);
  }
};
