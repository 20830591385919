// import throttle from 'lodash/throttle';

// const setAnchorRange = throttle((editor, range) => {
//   editor.selection.setAnchorRange(range);
// }, 0, {
//   leading: false,
//   trailing: true,
// });

function computedRange(editor) {
  const selection = editor.selection.getSelection();
  if (!selection || !selection.toString() || selection.toString().trim().length <= 0) {
    // console.log('reset');
    editor.selection.setAnchorRange(null);
    return;
  }
  if (selection && selection.toString() && selection.toString().length > 0) {
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      // console.log('set', range, range.collapsed);
      editor.selection.setAnchorRange(range);
      // setAnchorRange(editor, { range, text: selection.toString() });
    }
  }
}

export default function event(editor) {
  document.addEventListener('selectionchange', () => {
    const activeElement = document && document.activeElement;
    // console.log('activeElement', activeElement);
    const editorElement = document.querySelector('.editor-view');

    if (activeElement && editorElement.contains(activeElement)) {
      computedRange(editor);
    }
  }, false);
}
