<template>
  <div class="i-select-body" :style="{width}" @click="showDropdown" v-click-outside="closeDropdown">
    <div class="i-select-trigger" :class="triggerClass">
      <slot>
        <p v-text="selected && selected[props.label] ? selected[props.label] : '请选择'"></p>
        <i class="i-select-suffix iconfont icon-pull"></i>
      </slot>
    </div>
    <teleport to="#modals">
      <ul class="i-select-dropdown" :style="customStyle" v-show="visible">
        <li
          v-for="(item, index) in list"
          :key="index"
          :class="{'active': selected && selected[props.valueKey] === item[props.valueKey]}"
          @click.stop="clickHandle(item[props.valueKey])"
        >{{item.key}}</li>
      </ul>
    </teleport>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import derections from './direction';
import useSelect from './useSelect';

const defaultProps = { label: 'key', valueKey: 'value' };
export default defineComponent({
  name: 'ISelect',
  model: {
    prop: 'value',
    event: 'emitSelectChange',
  },
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    props: {
      type: Object,
      default: () => defaultProps,
    },
    list: {
      type: Array,
      require: true,
      default: () => [],
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    triggerClass: String,
    width: [String, Number],
  },
  directives: {
    clickOutside: derections.clickOutside,
  },
  setup(props, ctx) {
    // console.log('props S', props);
    // watch(props, (newVal) => {
    //   console.log('newVal', newVal);
    // });
    return {
      ...useSelect(props, ctx),
    };
  },
});
</script>
<style lang="scss">
  .i-select-body {
    position: relative;
    width: fit-content;
    .i-select-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 28px;
      p {
        flex: 1;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .i-select-suffix {
        padding: 0 5px;
        box-sizing: border-box;
      }
    }
  }
  .i-select-dropdown {
      position: absolute;
      top: 40px;
      left: 0;
      width: fit-content;
      overflow: auto;
      list-style: none;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      z-index: 9;
      li {
        line-height: 30px;
        padding: 0 10px;
        cursor: pointer;
        &:hover {
          background: rgba(65, 191, 138, 0.2);
        }
        &.active {
          background: rgba(65, 191, 138, 0.9);
          color: #fff;
        }
      }
    }
</style>
