import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import baseUi from './components/ui/index';
import App from './App.vue';
import router from './router';

createApp(App)
  .use(router)
  .use(ElementPlus, { size: 'mini', zIndex: 3000 })
  .use(baseUi)
  .mount('#app');
