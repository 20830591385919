<template>
  <div class="editor-library" id="editor-library">
    <ul class="nav">
      <li class="nav-item">
        <span class="icon"></span>
        <p class="name">模版中心</p>
      </li>
    </ul>
    <div class="content">
      <library-head></library-head>
      <div class="content-warp">
        <component :is="tplContent"></component>
      </div>
    </div>
    <!-- <el-button @click="parseTplHandle">解析模板</el-button>
    <ul class="tpl-list">
      <li
        tabindex="1"
        v-for="tpl in templateList"
        :key="tpl.id"
        @click="useTemplate(tpl)"
        v-html="tpl.template">
      </li>
    </ul> -->
  </div>
</template>
<script>
import { defineComponent, inject, reactive } from 'vue';
import libraryHead from './libraryHead.vue';
import tplContent from './tplContent.vue';
import tplList from './tplList';

function getTextModel(model) {
  console.log('model', model, Array.isArray(model));
  const models = Array.isArray(model) ? model : new Array(model);
  console.log('keys', models);
  const findText = models.find((modelItem) => modelItem && modelItem.menuType === 'text');
  console.log('findText', findText);
  if (findText) {
    return findText;
  }
  for (let i = 0; i < models.length; i += 1) {
    if (models[i]) {
      return getTextModel(models[i].colList || models[i].nodes);
    }
  }
}
export default defineComponent({
  name: 'IEditorTplLibrary',
  components: {
    libraryHead,
  },
  setup() {
    const IEditor = inject('IEditor');
    const templateList = reactive([...tplList]);

    function useTemplate(tpl) {
      const { matrix } = tpl;
      const replaceModelData = IEditor.content.getReplaceModelStatus();

      // 替换模板
      if (replaceModelData && replaceModelData.status) {
        const textModel = getTextModel(matrix);
        // console.log('textModel', textModel);
        if (textModel && replaceModelData.content) {
          textModel.text.setModelContent(replaceModelData.content);
        }
        const modelData = IEditor.content.getPresentModel();
        const { col, nodeIndex } = modelData;
        col.replace(nodeIndex, matrix);
        IEditor.content.setReplaceModelStatus(null);
        return;
      }
      IEditor.content.insertCell(matrix, null, IEditor);
    }

    async function parseTplHandle() {
      console.log('IEditor.model', IEditor.model);
      const tpl = await IEditor.model.parseTpl(IEditor);
      templateList.push(tpl);
      console.log('tplList', templateList);
    }

    return {
      tplContent,
      templateList,
      parseTplHandle,
      useTemplate,
    };
  },
});
</script>
<style scoped lang="scss">
.editor-library {
  display: flex;
  height: 100%;
  background: #fff;
  .nav {
    width: 70px;
    height: 100%;
    border: 1px solid #efefef;
    box-sizing: border-box;
    background: #fff;
    color: #565353;
    .nav-item {
      margin: 15px 0;
      cursor: pointer;
      text-align: center;
      &.active {
        background: linear-gradient(49deg,#ff0905,#ff3802);
        box-shadow: 0 2px 4px 0 rgba($color: #ff3802, $alpha: 1.0);
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 29px;
        height: 29px;
        border-radius: 50%;
        margin: 0 auto;
        border: 1px solid #ff5d31;
      }
      .name {
        line-height: 20px;
        margin-top: 3px;
        font-size: 14px;
        font-weight: 400;
        color: #565353;
      }
    }
  }
  .content {
    width: 360px;
    height: 100%;
    border: 1px solid #efefef;
    border-left: none;
    box-sizing: border-box;
    .content-warp {
      height: calc(100% - 80px);
      overflow: hidden;
    }
  }
}
</style>
