export function isString(str) {
  return str && typeof str === 'string';
}

export function isObject(val) {
  return typeof val === 'object' && String(val).slice(8, -1) === 'object';
}

export function isFunction(val) {
  return val && typeof val === 'function';
}

export function isElement(elem) {
  return elem.nodeType && elem.nodeType === 1;
}

export function isTextElement(elem) {
  if (!elem) {
    return null;
  }
  return (elem.nodeName && elem.nodeName === 'P')
    || (elem.getAttribute('cell-type') && elem.getAttribute('cell-type') === 'text'); // 标记了文本操作单元
}

export function isBlock(node) { // 验证元素是否是指定的block元素（display: block）
  if (isElement(node)) {
    const BLOCK_NODES = ['section', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    return BLOCK_NODES.includes(node.nodeName.toLowerCase());
  }
  return false;
}

export function isContainer(containers, element) { // 验证指定元素是否属于预定义的元素标签
  return element && element.nodeName && containers.indexOf(element.nodeName.toLowerCase()) > -1;
}

// 指定的target是否在编辑器中
export function isInEditor(target, editor) {
  return editor.editorBodyElem.contains(target) || editor.controlPanelElem.contains(target);
}
