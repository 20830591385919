import markMenus from './markMenu';
import selectMenus from './selectMenu';
import panelMenus from './panelMenu';
import blockMenus from './blockMenu';
import featureMenus from './featureMenu';
import popupMenus from './popupMenu';
import singleValMenu from './singleValMenu';

export default {
  ...selectMenus,
  ...markMenus,
  ...panelMenus,
  ...featureMenus,
  ...blockMenus,
  ...popupMenus,
  ...singleValMenu,
};
