import { isString } from '../../utils/check';

const INSTANCE_CLASSNAME = 'ipb-editor-wrap';
function is(elm, selector) {
  let i;

  if (!elm) {
    return false;
  }

  if (!Array.isArray(elm)) {
    // Simple all selector
    if (selector === '*') {
      return elm.nodeType === 1;
    }

    // Simple selector just elements
    if (/^([a-z0-9],?)+$/i.test(selector)) {
      const selectors = selector.toLowerCase().split(/,/);
      const elmName = elm.nodeName.toLowerCase();

      for (i = selectors.length - 1; i >= 0; i -= 1) {
        if (selectors[i] === elmName) {
          return true;
        }
      }

      return false;
    }

    // Is non element
    if (elm.nodeType && elm.nodeType !== 1) {
      return false;
    }
  }

  const elms = !Array.isArray(elm) ? [elm] : elm;

  console.log('elms', elms);
  return null;
}

function getElement(elem) {
  return elem && document && isString(elem) ? document.querySelector(elem) : elem;
}

function setAttribs(elm, attrs) {
  const node = getElement(elm);

  if (!node) {
    return;
  }

  Object.keys(attrs).forEach((attr) => {
    node.setAttribute(attr, attrs[attr]);
  });
}

function setHtml(elm, html) {
  const node = getElement(elm);

  if (!node) {
    return;
  }

  node.innerHTML = html;
}

function add(parentElem, name, attrs, html, create) {
  const newElem = typeof name === 'string' ? document.createElement(name) : name;
  setAttribs(newElem, attrs);

  if (html) {
    if (typeof html !== 'string' && html.nodeType) {
      newElem.appendChild(html);
    } else if (typeof html === 'string') {
      setHtml(newElem, html);
    }
  }

  return !create ? parentElem.appendChild(newElem) : newElem;
}

function findSib(node, selector, name) {
  let func = selector;

  if (node) {
    // If expression make a function of it using is
    if (typeof selector === 'string') {
      func = (elm) => is(elm, selector);
    }

    // Loop all siblings
    for (let sibNode = node[name]; sibNode; sibNode = node[name]) {
      if (typeof func === 'function' && func(sibNode)) {
        return node;
      }
    }
  }

  return null;
}

function addClass(elem, cls) {
  if (!elem || !elem.nodeType) {
    return;
  }
  elem.classList.add(cls);
}

function createElem(tagName, attrs, html) {
  return add(document.createElement(tagName), tagName, attrs, html, true);
}

function findCommonAncestor(aNode, bNode) {
  let ps = aNode;
  let pe;

  while (ps) {
    pe = bNode;

    while (pe && ps !== pe) {
      pe = pe.parentNode;
    }

    if (ps === pe) {
      break;
    }

    ps = ps.parentNode;
  }

  if (!ps && aNode.ownerDocument) {
    return aNode.ownerDocument.documentElement;
  }

  return ps;
}

function getRoot() {
  return document.body;
}

function getParents(elm, selector, root, collect) {
  const result = [];
  let selectorVal;
  let newSelector = selector;

  let element = getElement(elm);
  const newCollect = collect === undefined;
  const newRoot = root || (getRoot().nodeName !== 'BODY' ? getRoot().parentNode : null);

  if (isString(selector)) {
    selectorVal = selector;

    if (selector === '*') {
      newSelector = (node) => node.nodeType === 1;
    } else {
      newSelector = (node) => is(node, selectorVal);
    }
  }

  while (element) {
    if (element === newRoot) {
      break;
    }

    if (!newSelector || (typeof newSelector === 'function' && newSelector(element))) {
      if (collect) {
        result.push(element);
      } else {
        return [element];
      }
    }

    element = element.parentNode;
  }

  return newCollect ? result : null;
}

function getParent(node, selector, root) {
  const parents = getParents(node, selector, root, false);
  return parents && parents.length > 0 ? parents[0] : null;
}

function getPrev(node, selector) {
  return findSib(node, selector, 'previousSibling');
}

function getNext(node, selector) {
  return findSib(node, selector, 'nextSibling');
}

function findParentStyle(elem, styleName, root = null) {
  if (elem.nodeName.toUpperCase() === 'HTML' || elem.nodeName.toUpperCase() === 'BODY') {
    return null;
  }

  if (!elem.parentNode) {
    return null;
  }
  const rootElement = root ? document.querySelector(root) : document.querySelector(`.${INSTANCE_CLASSNAME}`);

  if (rootElement && !rootElement.contains(elem)) {
    return null;
  }

  const hasStyle = elem.style[styleName] !== null && elem.style[styleName] !== undefined && elem.style[styleName] !== '';

  if (hasStyle) {
    return elem;
  }

  return findParentStyle(elem.parentNode, styleName, root);
}

function getStyle(elem, styleName, root = '') {
  const element = elem;
  if (!element || element.nodeType !== 1) {
    return '';
  }

  const styleResult = element.style[styleName];

  if ((!styleResult || styleResult === 'initial') && root) {
    const parentElem = findParentStyle(element, styleName, root);
    return parentElem && parentElem.style[styleName];
  }
  return styleResult;
}

function hasClass(elm, cls) {
  if (!elm) {
    return null;
  }
  return [...elm.classList].includes(cls);
}

function isEmpty() {}

function setStyle(elem, styleArg) {
  const element = elem;
  if (!elem || elem.nodeType !== 1) {
    return;
  }
  if (!styleArg || typeof styleArg !== 'object') {
    return;
  }
  Object.keys(styleArg).forEach((styleName) => {
    element.style[styleName] = styleArg[styleName];
  });
}

function setCellType(elm, type) {
  setAttribs(elm, {
    'cell-type': type,
  });
}

export default {
  addClass,
  createElem,
  findCommonAncestor,
  getElement,
  getParent,
  getPrev,
  getNext,
  getStyle,
  hasClass,
  isEmpty,
  setAttribs,
  setStyle,
  setCellType,
};
