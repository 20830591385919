/* 需要进行标记的menu, 只适用于一些简单的在两个值之间切换的样式 */
import { isMarkActive, toggleMark } from './reaction';

export default {
  bold: {
    text: '加粗',
    icon: 'bold',
    type: 'mark',
    format: 'fontWeight',
    value: 'bold',
    active: isMarkActive,
    action: (editor) => toggleMark(editor, { format: 'fontWeight', value: 'bold' }),
  },
  italic: {
    text: '斜体',
    icon: 'italic',
    type: 'mark',
    format: 'fontStyle',
    value: 'italic',
    active: isMarkActive,
    action: (editor) => toggleMark(editor, { format: 'fontStyle', value: 'italic' }),
  },
};
