import cell from './cell';
import present from './present';

export default {
  ...cell,
  ...present,
  getHtmlContent() {
    const editorViewElem = document.querySelector('.editor-view');
    const htmlData = [...editorViewElem.querySelectorAll('.tpl-scope')].reduce((result, item) => {
      return `${result}${item.innerHTML}`;
    }, '');

    return htmlData;
  },
};
