<template>
  <router-view/>
</template>

<style lang="scss">
@import 'assets/css/app.scss';
  #app {
    font-family: PingFangSC-Regular, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    line-height: 1.6;
    font-size: 12px;
    color: #2c3e50;
    background: #f9f9f9;
  }
</style>
