import { domParse } from '../../../utils/util';
import compileElement from './compile';

async function chooseFile(editor) {
  const file = await editor.utils.uploadFile('text/html', false);
  return file;
}

/**
 * 读取文件内容
 * @param {File} file
 * @returns fileContent
 */
function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsText(file);
  });
}

/**
 * 创建模板模型
 * @param {String} tplContent 模板字符串
 * @returns 模型
 */
function createModel(tplContent) {
  const tplDom = typeof tplContent === 'string' ? domParse(tplContent) : tplContent;
  const tplChildren = tplDom.childNodes;
  if (!tplChildren || !tplChildren.length || tplChildren.length > 1) {
    throw new Error('模板格式错误', tplChildren);
  }
  // 开始解析
  const model = compileElement(tplChildren);
  // 解析成功，获取到json model
  return model;
}

/**
 * 将html字符串解析成模型
 * @param {String} htmlStr
 * @returns
 */
export function parseTplStr(htmlStr) {
  if (!htmlStr) {
    throw new Error('htmlStr错误');
  }
  return createModel(htmlStr);
}

/**
 * 选择本地文件并解析成模板
 * @param {Object} editor 编辑器实例
 * @returns 模板
 */
export default async function parseTplFile(editor) {
  // 上传文件, 限制后缀为html
  const file = await chooseFile(editor);
  // 读取文件内容
  const fileContent = await readFile(file);
  if (!fileContent) {
    throw new Error('模板文件为空');
  }
  const fileName = file.name.split('.').shift();
  const model = createModel(fileContent);

  return {
    id: Date.now(),
    fileName,
    template: fileContent,
    matrix: model,
  };
}
