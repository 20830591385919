import { ref } from 'vue';
import updateContent from './updateContent';

// anchorRange仅记录编辑区域最近发生的一条range
const anchorRange = ref(null);
function setAnchorRange(val) {
  anchorRange.value = val;
}

function getAnchorRange() {
  return anchorRange.value;
}

function getSelection() {
  if (window && window.getSelection) {
    return window.getSelection();
  }
  if (document && document.getSelection) {
    return document.getSelection();
  }
  if (document && document.selection) {
    return document.selection.createRange().text;
  }
  return null;
}

function getRange() {
  const selection = getSelection();
  if (!selection || !selection.rangeCount) {
    return null;
  }
  return selection.getRangeAt(0);
}

function resetRange(range) {
  if (!range) {
    return;
  }
  const selection = getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

export default {
  updateContentStyle: updateContent,
  getSelection,
  getRange,
  resetRange,
  setAnchorRange,
  getAnchorRange,
};
