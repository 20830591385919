<template>
  <div class="i-panel-body" @click="showDropdown" v-click-outside="closeDropdown">
    <div class="i-panel-trigger">
      <slot name="reference">
        <p>请选择</p>
      </slot>
    </div>
    <div class="i-panel-dropdown" v-show="visible">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import direction from './direction';

export default defineComponent({
  name: 'IPanel',
  directives: {
    clickOutside: direction.clickOutside,
  },
  setup(props, ctx) {
    const visible = ref(false);

    function showDropdown() {
      visible.value = true;
    }

    function closeDropdown() {
      visible.value = false;
    }

    function emitSelectChange(value) {
      ctx.emit('change', value);
    }

    onMounted(() => {});
    return {
      visible,
      showDropdown,
      closeDropdown,
      emitSelectChange,
    };
  },
});
</script>
<style scoped lang="scss">
  .i-panel-body {
    position: relative;
    width: fit-content;
    .i-panel-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // height: 28px;
      p {
        flex: 1;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
    .i-panel-dropdown {
      position: absolute;
      top: 40px;
      left: 0;
      width: fit-content;
      overflow: auto;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      z-index: 9;
    }
  }
</style>
