export const markButton = (props) => (
  <>
    <span
      className={`menu-item ${props.menu.active && props.menu.active(props.editor, props.menu.format, props.menu.value) ? 'active' : ''}`}
      tableindex={1}
      title={props.menu.text}
      onClick={(event) => props.menu.action(props.editor, event)}
    >
      {
        props.menu.icon
          ? <i class={`iconfont icon-${props.menu.icon}`}></i>
          : <span>{props.menu.text}</span>
      }
    </span>
  </>
);

export const selectButton = (props) => (
  <>
    <i-select
      class='menu-item'
      list={props.menu.options}
      value={props.menu.value(props.editor, props.menu)}
      isDefault
      onChange={(val) => props.menu.action(props.editor, props.menu, val)}
    ></i-select>
  </>
);

export const panelButton = (props) => (
  <>
    <i-panel
      class='menu-item'
    >
      <template v-slot:reference>
        {
          props.menu.icon
            ? <i class={`iconfont icon-${props.menu.icon}`}></i>
            : <span>{props.menu.text}</span>
        }
      </template>
    </i-panel>
  </>
);

export const colorButton = (props) => {
  const slots = {
    reference: () => (
      <>
        <span
          class='menu-item'
          tableindex={1}
          title={props.menu.text}
          // onClick={() => props.menu.action(props.editor)}
        >
          {
            props.menu.icon
              ? <i style={{ color: props.menu.active(props.editor, props.menu.format) }} class={`iconfont icon-${props.menu.icon}`}></i>
              : <span>{props.menu.text}</span>
          }
        </span>
      </>
    ),
    default: () => (
      <>
        <i-color-picker onChange={(colors) => props.menu.action(props.editor, colors.hex)}></i-color-picker>
      </>
    ),
  };
  return (
    <>
      <i-panel v-slots={slots}></i-panel>
    </>
  );
};

export const blockButton = (props) => (
  <>
    <span
      class='menu-item'
      tableindex={1}
      title={props.menu.text}
      onClick={() => props.menu.action(props.editor)}
    >
      {
        props.menu.icon
          ? <i class={`iconfont icon-${props.menu.icon}`}></i>
          : <span>{props.menu.text}</span>
      }
    </span>
  </>
);
