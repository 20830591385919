<template>
  <div class="control-panel" ref="controlPanel" :class="{'active': visible}">
    <el-scrollbar
    height="100%"
    view-style="height: 100%;">
      <text-panel class="c-panel-card"></text-panel>
      <image-panel class="c-panel-card"></image-panel>
      <link-panel class="c-panel-card"></link-panel>
      <!-- <color-theme-panel class="c-panel-card"></color-theme-panel> -->
      <btns-panel class="c-panel-card"></btns-panel>
      <complex-panel class="c-panel-card"></complex-panel>
    </el-scrollbar>
  </div>
</template>
<script>
import { defineComponent, ref, inject, onMounted } from 'vue';
import textPanel from './panel/textPanel.vue';
import imagePanel from './panel/imagePanel.vue';
import linkPanel from './panel/linkPanel.vue';
// import colorThemePanel from './panel/colorTheme.vue';
import btnsPanel from './panel/btnsPanel.vue';
import complexPanel from './panel/complexPanel.vue';
import { isInEditor } from '../../utils/check';

export default defineComponent({
  name: 'controlPanel',
  components: {
    textPanel,
    imagePanel,
    linkPanel,
    // colorThemePanel,
    btnsPanel,
    complexPanel,
  },
  setup() {
    const IEditor = inject('IEditor');
    const controlPanel = ref(null);
    const visible = ref(null);

    IEditor.utils.emitter.on('menuChange', () => {
      visible.value = true;
    });

    onMounted(() => {
      document.addEventListener('click', (event) => {
        const { target } = event;

        if (!isInEditor(target, IEditor)) {
          visible.value = false;
        }
      }, false);
    });

    return {
      visible,
      controlPanel,
    };
  },
});
</script>
<style lang="scss">
.control-panel {
  width: 0;
  flex-shrink: 0;
  height: 100%;
  box-shadow: 0 4px 12px rgba(40,120,255, .11);
  background: #fff;
  transition: all .3s ease-in-out;
  &.active {
    width: 220px;
  }
  .c-panel-card {
    padding: 10px 15px;
    border-bottom: 5px solid #f0f0f0;
    box-sizing: border-box;
    &:last-child {
      border: 0;
    }
    .panel-block {
      margin-bottom: 10px;
      .row-chunk {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .row-title {
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
