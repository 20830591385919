export const listenTypes = {};

export default function listenEvent(instance) {
  instance.listenType('parseFile', async () => {
    const tpl = await instance.IEditorAPI.model.parseTpl(instance.IEditorAPI);
    instance.IEditorAPI.content.clearCell();
    instance.IEditorAPI.content.insertCell(tpl.matrix, null, instance.IEditorAPI);
    console.log('tpl', tpl);
    return tpl;
  });

  instance.listenType('getContent', () => {
    const htmlContent = instance.IEditorAPI.content.getHtmlContent();
    return htmlContent;
  });
}