<template>
  <div
    id="editor-default-write"
    contenteditable="true"
    class="editor-default-input"
    :class="{'input-active': isActive}"
    @focus="focusHandle"
    @blur="blurHandle"
  >
    <p>&ZeroWidthSpace;</p>
  </div>
</template>
<script>
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'defaultWrite',
  setup() {
    const IEditor = inject('IEditor');
    const isActive = ref(false);

    function focusHandle() {
      isActive.value = true;
    }

    function blurHandle(event) {
      const { currentTarget } = event;

      const textContent = currentTarget.textContent.replace(/\u200B/g, '');
      if (textContent) {
        // 创建一个文本区域，存放文本内容
        const fragment = document.createDocumentFragment();
        fragment.append(...Array.from(currentTarget.childNodes));

        const content = IEditor.utils.dom.createElem('section', {}, fragment);

        const cellModel = IEditor.model.producer.textModel(content.innerHTML);
        // const cellModel = IEditor.model.gen.createTextNode({ content });
        IEditor.content.insertCell(cellModel, null, IEditor);

        currentTarget.appendChild(IEditor.utils.dom.createElem('p', {}, '&ZeroWidthSpace;'));
      }

      isActive.value = false;
    }

    return {
      isActive,
      focusHandle,
      blurHandle,
    };
  },
});
</script>
<style scoped lang="scss">
.editor-default-input {
  position: relative;
  border: 1px dashed #ddd;
  background: #fff;
  &:before {
    position: absolute;
    content: "在这里输入内容";
    color: #c3c3c3;
    font-size: 0.8em;
    line-height: 2;
    padding-left: 0.5em;
  }
}
.input-active {
  &:before {
    visibility: hidden;
  }
}
</style>
