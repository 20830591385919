<template>
  <div class="home">
    <i-editor :apiKey="key" :config="{}"></i-editor>
  </div>
</template>

<script>
import { IEditor } from '../components/IEditor/index';

export default {
  name: 'Home',
  components: {
    IEditor,
  },
  setup() {
    return {
      key: 'dsafdsafds21',
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
}
</style>
