function emitContentChange(editor) {
  const content = editor.content.getHtmlContent();
  editor.message.pushMessage('change', content);
}

export default class UndoManager {
  constructor(editor) {
    this.editor = editor;
    this.undoStack = [];
    this.redoStach = [];
  }

  record(todo) {
    this.undoStack.push(todo);
    if (!todo.isDoc) {
      todo.do();
      emitContentChange(this.editor);
    }

    this.redoStack = [];
  }

  undo() {
    const record = this.undoStack.pop();
    if (!record) {
      return;
    }
    if (record.isDoc) {
      document.execCommand('undo');
    } else {
      record.undo();
    }
    emitContentChange(this.editor);
    this.redoStack.push(record);
  }

  redo() {
    const record = this.redoStack.pop();
    if (!record) {
      return;
    }
    if (record.isDoc) {
      document.execCommand('redo');
    } else {
      record.do();
    }
    this.undoStack.push(record);
  }
}
