<template>
  <div class="toolbar" v-show="visible" :style="toolPos">
    <complex-toolbar></complex-toolbar>
    <text-toolbar></text-toolbar>
    <image-toolbar></image-toolbar>
    <table-toolbar></table-toolbar>
  </div>
</template>
<script>
import { defineComponent, computed, inject, ref, onMounted } from 'vue';
import complexToolbar from './complexToolbar.vue';
import textToolbar from './textToolbar.vue';
import imageToolbar from './imageToolbar.vue';
import tableToolbar from './tableToolbar.vue';
import { isInEditor } from '../../utils/check';

export default defineComponent({
  name: 'editorToolbar',
  components: {
    complexToolbar,
    textToolbar,
    imageToolbar,
    tableToolbar,
  },
  setup() {
    const IEditor = inject('IEditor');
    const top = ref(null);
    const left = ref(null);
    const menuType = ref(null);
    const visible = ref(false);

    IEditor.utils.emitter.on('menuChange', (data) => {
      const { currentTarget } = data;
      if (!data.menuType) {
        visible.value = false;
        return;
      }
      const { offsetTop, offsetLeft, offsetHeight } = currentTarget;
      top.value = offsetTop - 78 < 50 ? offsetTop + offsetHeight + 10 : offsetTop - 50;
      left.value = !visible.value ? offsetLeft - 110 : offsetLeft;
      visible.value = true;
      menuType.value = data.menuType;
    });

    onMounted(() => {
      document.addEventListener('click', (event) => {
        const { target } = event;

        if (!isInEditor(target, IEditor)) {
          visible.value = false;
        }
      }, false);
    });

    const toolPos = computed(() => ({
      top: `${top.value}px`,
      left: `${left.value}px`,
    }));
    return {
      visible,
      menuType,
      toolPos,
    };
  },
});
</script>
<style lang="scss">
.toolbar {
  position: absolute;
  // opacity: 0;
  transition: all .3s ease-in-out;
  & > div {
    margin-bottom: 5px;
  }
  &.active {
    opacity: 1;
  }
  .toolbar-menu {
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    .menu-item {
      height: 40px;
      line-height: 40px;
      padding: 0 6px;
      cursor: pointer;
      &:hover, &.active {
        background: #f0f0f0;
      }
    }
  }
}
</style>
