<template>
  <ul class="class-panel">
    <li class="class-item"
      v-for="(category, index) in classDataList"
      :key="index"
      :class="{'active': activeIndex === index}"
      @click="setActiveIndex(index)"
      @mouseover="categoryIndex = index"
      @mouseout="categoryIndex = null">
      <p>{{category.name}}</p>
      <template v-if="category.children && category.children.length">
        <ul class="child-class" v-show="categoryIndex === index">
          <li class="child" v-for="(child, childIndex) in category.children" :key="childIndex">
            <p class="child-name">{{child.name}}</p>
          </li>
        </ul>
      </template>
    </li>
  </ul>
</template>
<script>
import { ref } from 'vue';

const classDataList = [
  {
    name: '标题',
    children: [
      {
        name: '基础标签',
      },
      {
        name: '图片标签',
      },
      {
        name: '线框标签',
      }
    ],
  },
  {
    name: '卡片',
  },
  {
    name: '图文',
    children: [
      {
        name: '单图',
      },
      {
        name: '多图',
      },
      {
        name: '滑动拼接',
      }
    ],
  },
  {
    name: '引导',
  }
];
export default {
  name: 'class-panel',
  setup() {
    const categoryIndex = ref(null);
    const activeIndex = ref(null); // 当前选中的大类index
    const childActiveId = ref(null); // 当前选中的子类id

    /**
     * 设置选中的大类index
     */
    function setActiveIndex(index) {
      activeIndex.value = index;
    }

    return {
      classDataList,
      categoryIndex,
      activeIndex,
      setActiveIndex,
      childActiveId,
    };
  },
}
</script>
<style lang="scss" scoped>
.class-panel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .class-item {
    position: relative;
    width: 60px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    &.active, &:hover {
      border-bottom: 1px solid #ff3802;
      box-sizing: border-box;
    }
    .child-class {
      position: absolute;
      left: 0;
      top: 40px;
      width: 100px;
      padding: 5px 0;
      box-shadow: 0 2px 12px 0 #efefef;
      border-radius: 3px;
      border: 1px solid #efefef;
      box-sizing: border-box;
      background: #fff;
      .child {
        height: 28px;
        line-height: 28px;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 14px;
        text-align: left;
        &.active, &:hover {
          color: #fff;
          background: #ff3802;
        }
      }
    }
  }
}
</style>
