export default class Emitter {
  constructor() {
    this.events = {};
  }

  /**
   * 发布者
   */
  emit(eventName, data) {
    const callbacks = this.events[eventName];
    if (Array.isArray(callbacks)) {
      callbacks.forEach((callback) => {
        callback(data);
      });
    }
  }

  /**
   * 订阅者
   */
  on(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [callback];
    } else {
      this.events[eventName].push(callback);
    }
  }

  /**
   * 移除一个订阅
   */
  remove(eventName, callback) {
    if (this.events[eventName]) {
      this.events[eventName] = this.events[eventName].filter((cb) => cb !== callback);
    }
  }
}
