<template>
  <div class="text-panel" v-show="visible">
    <div class="panel-block">
      <div class="row-chunk">
        <span class="row-title">行间距</span>
        <el-input v-model="lineHeightVal" placeholder="按回车键设置行高" @change="setLineHeight">
          <template #suffix>
            <span>倍</span>
          </template>
        </el-input>
      </div>
      <div class="row-chunk">
        <span class="row-title">字间距</span>
        <el-input v-model="letterSpacingVal" placeholder="按回车键设置字间距" @change="setLetterSpace">
          <template #suffix>
            <span>像素</span>
          </template>
        </el-input>
      </div>
      <div class="row-chunk">
        <span class="row-title">页边距</span>
        <el-input v-model="pageMargin" placeholder="按回车键设置页边距" @change="setPageMargin">
          <template #suffix>
            <span>像素</span>
          </template>
        </el-input>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
  name: 'imagePanel',
  setup() {
    const IEditor = inject('IEditor');
    const modelData = ref(null);

    const visible = ref(false);
    const lineHeightVal = ref(null);
    const letterSpacingVal = ref(null);
    const pageMargin = ref(null);

    IEditor.utils.emitter.on('menuChange', (data) => {
      const { model, menuType } = data;
      modelData.value = model;
      lineHeightVal.value = model.style.lineHeight ? Number.parseInt(model.style.lineHeight, 10) : null;
      letterSpacingVal.value = model.style.letterSpacing ? Number.parseInt(model.style.letterSpacing, 10) : null;
      pageMargin.value = model.style.padding ? Number.parseInt(model.style.padding.split(' ')[1], 10) : null;
      visible.value = menuType === 'text';
    });

    /**
     * 设置行高
     * @param {Number} val
     */
    function setLineHeight(val) {
      const oldVal = modelData.value.style.lineHeight;
      IEditor.utils.undo.record({
        do: () => {
          modelData.value.style.lineHeight = val;
        },
        undo: () => {
          modelData.value.style.lineHeight = oldVal;
        },
      });
    }

    /**
     * 设置字间距
     * @param {Number} val
     */
    function setLetterSpace(val) {
      const oldVal = modelData.value.style.letterSpacing;
      IEditor.utils.undo.record({
        do: () => {
          modelData.value.style.letterSpacing = `${val}px`;
        },
        undo: () => {
          modelData.value.style.letterSpacing = oldVal;
        },
      });
    }

    /**
     * 设置也边距
     * @param {Number} val
     */
    function setPageMargin(val) {
      const oldVal = modelData.value.style.padding;
      IEditor.utils.undo.record({
        do: () => {
          modelData.value.style.padding = `0 ${val}px`;
        },
        undo: () => {
          modelData.value.style.padding = oldVal;
        },
      });
    }

    return {
      visible,
      lineHeightVal,
      letterSpacingVal,
      pageMargin,
      setLineHeight,
      setLetterSpace,
      setPageMargin,
    };
  },
});
</script>
<style scoped lang="scss">

</style>
