export default class ColList extends Array {
  constructor(...args) {
    super(...args);
  }

  remove(index) {
    return this.splice(index, 1)[0];
  }

  replace(index, colModel) {
    this.splice(index, 1, colModel);
  }

  insert(index, colModel) {
    this.splice(index, 0, colModel);
  }
}
