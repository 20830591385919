<template>
  <div class="image-toolbar" v-show="visible">
    <editor-menu :menus="menus" class-name="toolbar-menu"></editor-menu>
  </div>
</template>
<script>
import { defineComponent, inject, ref } from 'vue';
import editorMenu from '../public/menu/menu';

export default defineComponent({
  name: 'imageToolbar',
  components: {
    editorMenu,
  },
  setup() {
    const IEditor = inject('IEditor');
    const menus = IEditor.menus.filterMenus(['replaceImage', 'cropImage', 'imageLeftAlign', 'imageCenterAlign', 'imageRightAlign']);

    const visible = ref(false);

    IEditor.utils.emitter.on('menuChange', (data) => {
      visible.value = data.menuType === 'image';
    });

    return {
      menus,
      visible,
    };
  },
});
</script>
