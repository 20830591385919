<template>
  <div class="text-toolbar" v-show="visible">
    <editor-menu :menus="menus" class-name="toolbar-menu"></editor-menu>
  </div>
</template>
<script>
import { defineComponent, inject, ref } from 'vue';
import editorMenu from '../public/menu/menu';

export default defineComponent({
  name: 'textToolbar',
  components: {
    editorMenu,
  },
  setup() {
    const IEditor = inject('IEditor');
    const menus = IEditor.menus.filterMenus(['bold', 'italic', 'fontColor', 'bgColor',
      'underline', 'strikethrough', 'textLeftAlign', 'textCenterAlign', 'textRightAlign', 'textJustifyAlign',
    ]);

    const visible = ref(false);

    IEditor.utils.emitter.on('menuChange', (data) => {
      visible.value = ['text', 'table'].includes(data.menuType);
    });

    return {
      menus,
      visible,
    };
  },
});
</script>
