import { getElemStyle } from '../../../utils/util';

export default class ColGroup {
  constructor(node) {
    this.style = getElemStyle(node);
    this.nodes = [];
  }

  push(node) {
    this.nodes.push(node);
  }

  remove(index) {
    return this.nodes.splice(index, 1)[0];
  }

  insert(index, node) {
    this.nodes.splice(index, 0, node);
  }

  replace(index, node) {
    this.nodes.splice(index, 1, node);
  }
}
