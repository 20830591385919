/* 需要通过一个弹出面板进行交互的menu */
import { getColorActiveVal, toggleColor } from './reaction';
// import { getColorActiveVal } from './reaction';

export default {
  fontColor: {
    text: '字体颜色',
    icon: 'fontcolor',
    type: 'color',
    format: 'color',
    value: 'red',
    active: getColorActiveVal,
    action: (editor, value) => toggleColor(editor, { format: 'color', value }),
  },
  bgColor: {
    text: '字体背景',
    icon: 'bgColor',
    type: 'color',
    format: 'backgroundColor',
    value: 'red',
    active: getColorActiveVal,
    action: (editor, value) => toggleColor(editor, { format: 'backgroundColor', value }),
  },
};
