<template>
  <ul class="filter-panel">
    <li class="filter-item">推荐</li>
    <li class="filter-item">收藏</li>
    <li class="filter-item">最新</li>
    <li class="filter-item">最近使用</li>
  </ul>
</template>
<script>
import { ref } from 'vue';

export default {
  name: 'filterPanel',
  setup() {
    const active = ref(null);

    return {
      active,
    };
  },
}
</script>
<style lang="scss" scoped>
.filter-panel {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  .filter-item {
    // width: 50px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    box-sizing: border-box;
    text-align: center;
    &.active {
      border-bottom: 1px solid #ff3802;
    }
  }
}
</style>
