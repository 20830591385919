import { defineComponent, inject } from 'vue';
import { IEditor } from './renderAction';
import nodeRender from './renders/nodeRender';

export default defineComponent({
  name: 'renderModel',
  props: {
    matrix: Object,
    index: Number,
  },
  setup(props) {
    IEditor.value = inject('IEditor');

    function matrixClickHandle() {
      // console.log('还能检测到click吗');
      IEditor.value.content.setMatrixIndex(props.index);
      IEditor.value.content.setPresentMatrix(props.matrix);
    }

    return () => (
      <div class='tpl-scope' onClick={matrixClickHandle}>
        {
          <nodeRender model={props.matrix}></nodeRender>
        }
      </div>
    );
  },
});
