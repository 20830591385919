/* 需要打开窗口的menu */
import { togglePopup } from './reaction';

export default {
  cropImage: {
    text: '裁剪',
    icon: '',
    type: 'mark',
    active: null,
    action: (editor) => togglePopup(editor, 'crop'),
  },
};
