export const isBlockActive = () => {};

async function insertImage(editor) {
  const imageSrc = await editor.utils.uploadFile();
  const cellModel = editor.model.producer.imageModel(imageSrc);
  console.log('cellModel', cellModel);
  const replaceModelData = editor.content.getReplaceModelStatus();
  if (replaceModelData && replaceModelData.status) {
    const modelData = editor.content.getPresentModel();
    const { col, nodeIndex } = modelData;
    col.replace(nodeIndex, cellModel);
    editor.content.setReplaceModelStatus(null);
    return;
  }
  editor.content.insertCell(cellModel, null, editor);
}

function insertTable(editor) {
  const cellModel = editor.model.producer.tableModel(2, 3);
  console.log('tableCellModel', cellModel);
  editor.content.insertCell(cellModel, null, editor);
}

function insertList(editor, type) {
  const cellModel = editor.model.producer.listModel(type);
  console.log(cellModel);
  editor.content.insertCell(cellModel, null, editor);
}

export const toggleBlock = (editor, insertType) => {
  switch (insertType) {
    case 'image':
      insertImage(editor);
      break;
    case 'table':
      insertTable(editor);
      break;
    case 'ol': case 'ul':
      insertList(editor, insertType);
      break;
    default:
      break;
  }
};
