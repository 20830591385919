/* 需要执行单一功能的menu */
import {
  undoAction, redoAction, replaceImageAction,
  insertRow, insertCol, removeRow, removeCol,
  mergeToRight, mergeToBottom, replaceModel,
} from './reaction';

export default {
  undo: {
    text: '撤销',
    icon: 'undo',
    type: 'mark',
    active: null,
    action: (editor) => undoAction(editor),
  },
  redo: {
    text: '恢复',
    icon: 'redo',
    type: 'mark',
    active: null,
    action: (editor) => redoAction(editor),
  },
  replaceImage: {
    text: '换图',
    icon: '',
    type: 'mark',
    active: null,
    action: (editor) => replaceImageAction(editor),
  },
  insertRow: {
    text: '插入行',
    icon: 'insertRow',
    type: 'mark',
    active: null,
    action: (editor) => insertRow(editor),
  },
  insertCol: {
    text: '插入列',
    icon: 'insertCol',
    type: 'mark',
    active: null,
    action: (editor) => insertCol(editor),
  },
  removeRow: {
    text: '删除行',
    icon: 'delRow',
    type: 'mark',
    active: null,
    action: (editor) => removeRow(editor),
  },
  removeCol: {
    text: '删除列',
    icon: 'delCol',
    type: 'mark',
    active: null,
    action: (editor) => removeCol(editor),
  },
  mergeRow: {
    text: '向右合并',
    icon: 'mergeRight',
    type: 'mark',
    active: null,
    action: (editor) => mergeToRight(editor),
  },
  mergeCol: {
    text: '向下合并',
    icon: 'mergeBottom',
    type: 'mark',
    active: null,
    action: (editor) => mergeToBottom(editor),
  },
  replaceModel: {
    text: '替换模版',
    type: 'mark',
    active: null,
    action: (editor) => replaceModel(editor),
  },
};
