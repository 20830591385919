import IEditor from './src/index.vue';

const install = (Vue) => {
  Vue.component('IEditor', IEditor);
};

export default install;

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export {
  install,
  IEditor,
}
