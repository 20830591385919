import content from './content';
import event from './event';
import menus from './menus';
import model from './model';
import Message from './message';
import selection from './selection';
import Utils from './utils';

export default function createIEditor(config = {}) {
  class IEditor {
    constructor() {
      this.command = {};
      this.content = content;
      this.event = event(this);
      this.menus = menus;
      this.model = model;
      this.message = new Message(this);
      this.selection = selection;
      this.utils = new Utils(this, config);
    }

    setup(elms) {
      const { contentElm, controlPanelElem, editorBodyElem } = elms;
      this.contentElm = contentElm;
      this.controlPanelElem = controlPanelElem;
      this.editorBodyElem = editorBodyElem;
    }
  }
  return new IEditor();
}
