<template>
  <div ref="moveCell" class="move-cell" :style="dataMap.style">
    <template v-if="dataMap.visible">
      <div class="cell-mark"></div>
      <div class="cell-move">
        <span class="move-btn" title="向上移动" label="move" @click="moveCellHandle(0)">
          <i class="iconfont icon-topMove"></i>
        </span>
        <span class="move-btn" title="向下移动" label="move" @click="moveCellHandle(1)">
          <i class="iconfont icon-downMove"></i>
        </span>
      </div>
    </template> 
  </div>
</template>
<script>
import { defineComponent, inject, ref, reactive, watch, onMounted } from 'vue';
import { isInEditor } from '../../../utils/check';

const lastLeft = ref(0);
export default defineComponent({
  name: 'moveCell',
  props: {
    index: Number,
    total: Number,
  },
  setup(props) {
    const IEditor = inject('IEditor');
    const moveCell = ref(null);
    const dataMap = reactive({
      visible: false,
      style: {},
    });

    const modelIndex = IEditor.content.getMatrixIndex();

    watch(modelIndex, async (newModelIndex) => {
      if (newModelIndex === null) {
        lastLeft.value = 0;
      }
      if (props.index !== newModelIndex) {
        dataMap.style = {
          display: 'none',
        };
        dataMap.visible = false;
        return;
      }
      const parentElem = moveCell.value.parentElement;
      const { clientWidth, clientHeight, offsetTop, offsetLeft } = parentElem;
      dataMap.style = Object.assign(dataMap.style, {
        width: `${clientWidth}px`,
        height: `${clientHeight}px`,
        top: `${offsetTop}px`,
        left: `${!lastLeft.value ? offsetLeft - 110 : offsetLeft}px`,
      });
      lastLeft.value = offsetLeft;
      dataMap.visible = true;
    });

    onMounted(() => {
      document.addEventListener('click', (event) => {
        const { target } = event;

        const isMove = target.parentElement && target.parentElement.getAttribute('label') === 'move';
        if (!isInEditor(target, IEditor) && !isMove) {
          IEditor.content.setMatrixIndex(null);
          dataMap.visible = false;
        }
      }, false);
    });

    /**
     * 移动单元
     * @param {Number} dir 移动方向 0：上移, 1：下移
     */
    function moveCellHandle(dir) {
      const { index, total } = props;
      if (dir === 0 && index === 0) { // 当前是第一个，且往上移动
        return;
      }
      if (dir === 1 && index === total - 1) { // 当前是最后一个，且往下移动
        return;
      }
      IEditor.content.moveCell(index, dir, IEditor);
    }

    return {
      moveCell,
      dataMap,
      moveCellHandle,
    };
  },
});
</script>
<style scoped lang="scss">
.move-cell {
  position: absolute;
  //width: 100%;
  //left: 0;
  // z-index: -9999;
  .cell-mark {
    width: 100%;
    height: 100%;
    outline: 1px solid #409eff;
  }
  .cell-move {
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
    .move-btn {
      display: block;
      // padding: 2px;
      // border-radius: 50%;
      // border: 1px solid #666;
      cursor: pointer;
      i {
        font-size: 20px;
        font-style: normal !important;
      }
    }
  }
}
</style>
