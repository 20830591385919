<template>
  <div class="table-toolbar" v-show="visible">
    <editor-menu :menus="menus" class-name="toolbar-menu"></editor-menu>
  </div>
</template>
<script>
import { defineComponent, inject, ref } from 'vue';
import editorMenu from '../public/menu/menu';

export default defineComponent({
  name: 'tableToolbar',
  components: {
    editorMenu,
  },
  setup() {
    const IEditor = inject('IEditor');
    const menus = IEditor.menus.filterMenus(['insertRow', 'insertCol', 'mergeRow', 'mergeCol', 'removeRow', 'removeCol']);

    const visible = ref(false);

    IEditor.utils.emitter.on('tableChange', (data) => {
      visible.value = data.menuType === 'table';
    });
    IEditor.utils.emitter.on('menuChange', () => {
      visible.value = false;
    });

    return {
      menus,
      visible,
    };
  },
});
</script>
