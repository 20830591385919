import { defineComponent, inject, onUpdated } from 'vue';
import textRender from './textRender';

const tdRender = defineComponent({
  name: 'tdRender',
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: Number,
  },
  emits: ['tableAction'],
  setup(props, ctx) {
    function emitTdInfo() {
      ctx.emit('tableAction', {
        tdModel: props.model,
        tdIndex: props.index,
      });
    }
    onUpdated(() => {
      console.log('tdtdtdt', props.model);
    });
    return () => (<>
      <td
        colspan={props.model.colspan}
        rowspan={props.model.rowspan}
        style={props.model.style}
        onClick={ () => emitTdInfo() }
      >
        <textRender model={props.model}></textRender>
      </td>
    </>);
  },
});

const trRender = defineComponent({
  name: 'trRender',
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: Number,
  },
  emits: ['tableAction'],
  setup(props, ctx) {
    function emitTrInfo(tdInfo) {
      ctx.emit('tableAction', {
        trModel: props.model,
        trIndex: props.index,
        ...tdInfo,
      });
    }

    return () => (<>
      <tr>
        {
          props.model.tds.map((td, index) => <tdRender
            model={td}
            index={index}
            onTableAction={ (tdInfo) => emitTrInfo(tdInfo) }>
          </tdRender>)
        }
      </tr>
    </>);
  },
});

const tableRender = defineComponent({
  name: 'tableRender',
  props: {
    model: {
      type: Object,
      required: true,
    },
    col: Object,
    nodeIndex: Number,
  },
  setup(props) {
    const IEditor = inject('IEditor');

    function tableAction(data) {
      const tableData = {
        menuType: 'table',
        table: props.model.table,
        ...data,
      };
      IEditor.utils.emitter.emit('tableChange', tableData);
      IEditor.content.setPresentTable(tableData);
    }

    return () => (<>
      <div class="ie-cell table">
        <table style={{ ...props.model.table.style }}>
          <tbody>
            {
              props.model.table.trs.map((tr, index) => <trRender
                model={tr}
                index={index}
                onTableAction={ (data) => tableAction(data) }>
              </trRender>)
            }
          </tbody>
        </table>
      </div>
    </>);
  },
});

export default tableRender;
