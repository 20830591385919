import { defineComponent, inject } from 'vue';
import { cellAction } from '../publicAction';

export default defineComponent({
  name: 'imageRender',
  props: {
    model: {
      type: Object,
      required: true,
    },
    col: Object,
    nodeIndex: Number,
  },
  setup(props) {
    const IEditor = inject('IEditor');

    const emitModelData = {
      currentModel: props.model.image,
      nodeModel: props.model,
      col: props.col,
      nodeIndex: props.nodeIndex,
    };

    return () => (<>
      <section
        class="ie-cell image"
        contentEditable={true}
        link={props.model.image.link}
        style={{ overflow: 'hidden', ...props.model.image.style }}
        onClick={ (e) => cellAction(e, emitModelData, IEditor) }
      >
        <img src={props.model.image.src} style='max-width: 100%;display: block;' />
      </section>
    </>);
  },
});
