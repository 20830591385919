function isMarkStyle(style, format) {
  const styleVal = style[format];
  // console.log('format', format);
  // console.log('styleVal', styleVal);
  switch (format) {
    case 'fontWeight':
      return !styleVal || +styleVal === 400 ? null : styleVal;
    default:
      return !styleVal || styleVal === 'normal' || styleVal === 'initial' || styleVal.startsWith('none') ? null : styleVal;
  }
}

export const isMarkActive = (editor, format) => {
  const modelData = editor.content.getPresentModel();
  if (!modelData) {
    return false;
  }
  const { model, anchorEvent } = modelData;
  const target = anchorEvent && anchorEvent.target;
  if (target && target.nodeName === 'SPAN') {
    // 获取target的应用样式
    const style = window.getComputedStyle(target);
    return !!isMarkStyle(style, format);
  }

  return model ? !!model.style[format] : false;
};

export const toggleMark = (editor, menu) => {
  const { format, value } = menu;
  const { model } = editor.content.getPresentModel();
  const range = editor.selection.getRange();
  if (!range || range.collapsed) {
    if (model.style[format]) {
      const oldVal = model.style[format];
      editor.utils.undo.record({
        do: () => {
          delete model.style[format];
        },
        undo: () => {
          model.style[format] = oldVal;
        },
      });
    } else {
      editor.utils.undo.record({
        do: () => {
          model.style[format] = value;
        },
        undo: () => {
          delete model.style[format];
        },
      });
    }
  } else {
    // console.log('format', format, value);
    editor.utils.undo.record({
      isDoc: true,
    });
    document.execCommand('styleWithCSS', null, true);
    document.execCommand(value);
    // const selection = editor.selection.getSelection();
    // const innerHtml = editor.content.getCurrentTarget().value.innerHTML;
    // editor.selection.updateContentStyle(selection, editor, { format, value });
  }
};
