function getAlpha(rgba) {
  const rgb = rgba.match(/(\d(\.\d+)?)+/g);
  return rgb[3];
}

export const getColorActiveVal = (editor, format) => {
  const modelData = editor.content.getPresentModel();
  if (!modelData) {
    return false;
  }
  const { model, anchorEvent } = modelData;
  const target = anchorEvent && anchorEvent.target;
  if (target && target.nodeName === 'SPAN') {
    // 获取target的应用样式
    const colorVal = window.getComputedStyle(target)[format];
    const alpha = getAlpha(colorVal);
    return +alpha === 0 ? null : colorVal;
  }
  return model ? model.style[format] : null;
};

export const toggleColor = (editor, menu) => {
  const { format, value } = menu;
  const { model } = editor.content.getPresentModel();
  if (!model) {
    return;
  }
  const range = editor.selection.getRange();
  if (!range || range.collapsed) {
    const alpha = getAlpha(value);
    const oldVal = model.style[format];
    editor.utils.undo.record({
      do: () => {
        model.style[format] = +alpha === 0 ? null : value;
      },
      undo: () => {
        model.style[format] = oldVal;
      },
    });
  } else {
    // console.log('format', format, value);
    const newFormat = format === 'backgroundColor' ? 'backColor' : 'foreColor';
    editor.utils.undo.record({
      isDoc: true,
    });
    document.execCommand('styleWithCSS', null, true);
    document.execCommand(newFormat, false, value);
    // const selection = editor.selection.getSelection();
    // editor.selection.updateContentStyle(selection, editor, { format, value });
  }
};
