<template>
  <div class="ipb-editor" :IEditor="IEditor">
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent, provide } from 'vue';
// import createEditor from './index';

export default defineComponent({
  name: 'IEditor',
  props: {
    IEditor: Object,
  },
  setup(props) {
    // const IEditor = createEditor();
    provide('IEditor', props.IEditor);
    return {
      // IEditor,
    };
  },
});
</script>
