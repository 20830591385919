import { ref } from 'vue';

export const FONT_SIZE_ENUMS = [ // 字号列表
  {
    key: '10px',
    value: 10,
  },
  {
    key: '14px',
    value: 14,
  },
  {
    key: '16px',
    value: 16,
  },
  {
    key: '18px',
    value: 18,
  },
  {
    key: '30px',
    value: 30,
  },
];

// 可选字体列表
export const FONT_FAMILY_ENUMS = [];

// 可选行高列表
export const LINE_HEIGHT_ENUMS = [
  {
    key: '1.5',
    value: 1.5,
  },
  {
    key: '1.6',
    value: 1.6,
  },
  {
    key: '1.7',
    value: 1.7,
  },
  {
    key: '1.8',
    value: 1.8,
  },
  {
    key: '2.0',
    value: 2.0,
  },
];

// 可选字号列表
export const D_FONT_SIZE = {
  value: ref(16),
  defaultVal: 16,
  get(isRef = true) {
    return isRef ? this.value : this.value.value;
  },
  set(val) {
    this.value.value = val;
  },
};

export const D_LINE_HEIGHT = {
  value: ref(1.6),
  defaultVal: 1.6,
  get(isRef = true) {
    return isRef ? this.value : this.value.value;
  },
  set(val) {
    this.value.value = val;
  },
};

export const D_TEXT_ALIGN = {
  value: ref('left'),
  defaultVal: 'left',
  get(isRef = true) {
    return isRef ? this.value : this.value.value;
  },
  set(val) {
    this.value.value = val;
  },
};
