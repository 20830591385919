<template>
  <el-scrollbar
  height="100%"
  view-style="height: 100%;">
    <ul class="tpl-content">
      <li class="tpl-panel" tabindex="1"></li>
    </ul>
  </el-scrollbar>
</template>
<script>
export default {
  name: 'tplContent',
  setup() {
    return {};
  },
}
</script>
<style lang="scss" scoped>
.tpl-content {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  background: #f9f9f9;
  .tpl-panel {
    width: 100%;
    padding: 10px 8px;
    box-sizing: border-box;
    margin-bottom: 15px;
    background: #fff;
    &:hover {
      box-shadow: 0px 0px 8px 0px rgba(255, 56, 2, 0.28);
    }
  }
}
</style>
