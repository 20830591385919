function emitModel(IEditor, data) {
  IEditor.utils.emitter.emit('menuChange', data);
  IEditor.content.setPresentModel(data);
}

/**
 * 操作单元反应处理
 * @param {Object} event 事件对象
 * @param {Object} model 数据模型
 * @param {Object} IEditor 编辑器实例
 */
export function cellAction(event, model, IEditor) {
  // event.stopPropagation();
  const { currentModel, nodeModel, col, nodeIndex } = model;
  const { currentTarget } = event;
  emitModel(IEditor, {
    menuType: currentModel.menuType,
    model: currentModel,
    anchorEvent: event,
    nodeModel,
    col,
    currentTarget,
    nodeIndex,
  });
  // IEditor.content.setAnchorEvent(event);
}

export function clickNodeHandle() {}
