<template>
  <div class='i-color-picker'>
    <chrome v-model="colors" @input="inputHandle"></chrome>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue';
import chrome from './chrome.vue';

export default defineComponent({
  name: 'IColorPicker',
  components: {
    chrome,
  },
  setup(props, ctx) {
    const colors = ref('#194d33');

    function inputHandle(val) {
      ctx.emit('change', val);
    }

    onMounted(() => {
      // console.log('picker', pickerElem);
    });
    return {
      colors,
      inputHandle,
    };
  },
});
</script>
