import { defineComponent, h, inject, onUpdated } from 'vue';
import throttle from 'lodash/throttle';
import { cellAction } from '../publicAction';

export default defineComponent({
  name: 'textRender',
  props: {
    model: {
      type: Object,
      required: true,
    },
    col: Object,
    nodeIndex: Number,
  },
  setup(props) {
    const IEditor = inject('IEditor');

    // 从table组件中
    const contentModel = props.model.text || props.model.node; // 此处，从props中单独提取出来，contentModel将失去响应性
    // console.log('props.model.text.content', props.model.text.content);
    const contentDom = (contentModel.content ? h({
      template: (props.model.text && props.model.text.content) || (props.model.node && props.model.node.content),
    }) : null);

    onUpdated(() => {
      // console.log('onUpdated', contentModel, props.model);
    });

    const emitModelData = {
      currentModel: props.model.text || props.model.node,
      nodeModel: props.model,
      col: props.col,
      nodeIndex: props.nodeIndex,
    };

    const editableInputHandle = throttle((event) => {
      const htmlContent = event.target.innerHTML;
      const oldContent = contentModel.content;
      IEditor.utils.undo.record({
        do: () => {
          contentModel.content = htmlContent;
        },
        undo: () => {
          contentModel.content = oldContent;
        },
      });
    }, 1000, {
      leading: false,
      trailing: true,
    });

    // 此处style带定位是为了更好的设置toolbar的位置
    return () => (<>
      <section
        data-id={Math.random()}
        class="ie-cell text"
        contentEditable={true}
        link={contentModel.link}
        style={{ position: 'relative', ...contentModel.style }}
        onClick={ (e) => cellAction(e, emitModelData, IEditor) }
        onInput={ (e) => editableInputHandle(e) }
      >
        {
          // console.log('contentDom1111', contentDom)
        }
        {contentDom}
      </section>
    </>);
  },
});
