// import createEditor from '../core/index';
import { isFunction } from '../../utils/check';
import messageTypes from './types';
import listenEvent, { listenTypes } from './listen';

/**
 * message事件回调函数
 * @param {*} messageData 
 * @param {*} messageInstance 
 * @returns 
 */
function onMessage(messageData, messageInstance) {
  const { type, message } = messageData;
  const callback = messageTypes[type];

  if (!isFunction(callback)) {
    return;
  }
  callback(message, messageInstance);
}

function listenMessage(messageInstance) {
  window.addEventListener('message', function(event) {
    const { origin, data } = event;
    if (origin === window.location.origin) {
      return;
    }
    if (!data) {
      return;
    }
    messageInstance.lastOrigin = origin;
    onMessage(data, messageInstance);
  }, false);
}

export default class Message {
  constructor() {
    // console.log('editor', editor);
    listenMessage(this);
    listenEvent(this);
    this.lastOrigin = '';
  }

  /**
   * 设置需要监听的指令
   * @param {String} type 指令名称
   * @param {Function} callback 监听到指令时所执行的回调
   */
  listenType(type, callback) {
    listenTypes[type] = callback;
  }

  /**
   * 向外部推送消息
   * @param {String} type 指令名称
   * @param {Object} message 消息内容
   */
  pushMessage(type, message) {
    // console.log('pushMessage');
    window.parent.postMessage({ type, message }, this.lastOrigin || '*');
  }
}
