<template>
  <div class="link-panel">
    <div class="panel-block">
      <div class="row-chunk">
        <span class="row-title">链接</span>
        <el-input v-model="linkData" placeholder="按回车键确认链接" @change="setLink"></el-input>
      </div>
      <el-button :disabled="!linkData" type="danger" class="block-btn" @click="clearLink">清除链接</el-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, inject } from 'vue';
import { ElMessage } from 'element-plus';
import { isElement } from '../../../utils/check';

/**
 * 验证是否的a标签
 * @param {Node} elem 需要验证的元素
 * @returns Boolean
 */
function isLinkElem(elem) {
  return elem && elem.nodeName.toLowerCase() === 'a';
}

function restoreRange(selection, range) {
  selection.removeAllRanges(); // 清空所有 Range 对象
  selection.addRange(range); // 恢复保存的 Range
}

/**
 * 设置a标签的target
 * @param {Node} element 需要设置target属性的元素
 * @returns undefined
 */
function setLinkTarget(element) {
  if (!element || !isElement(element)) {
    return;
  }
  // 使用execCommand设置a标签时，如果选区中有多个元素，则会每一个元素都会设置a标签，则此处需要递归设置target
  const childElement = [...element.childNodes].filter((child) => isElement(child));
  childElement.forEach((child) => {
    if (child.nodeName.toLowerCase() === 'a') {
      child.setAttribute('target', '_blank');
    }
    setLinkTarget(child);
  });
}

/**
 * 创建a标签
 * @param {Object} range 当前选区
 * @param {String} urlVal a标签的href
 */
function createLink(selection, range, urlVal) {
  restoreRange(selection, range);
  console.log(1111111111111);
  document.execCommand('createLink', false, urlVal);
  setLinkTarget(range.commonAncestorContainer);
}

export default defineComponent({
  name: 'linkPanel',
  setup() {
    const IEditor = inject('IEditor');
    const modelData = ref(null);
    const anchorEventData = ref(null);

    const linkData = ref(null);

    IEditor.utils.emitter.on('menuChange', (data) => {
      const { model, anchorEvent } = data;
      const { target } = anchorEvent;

      if (isLinkElem(target)) {
        linkData.value = target.getAttribute('href');
      } else {
        linkData.value = model.link;
      }
      modelData.value = model;
      anchorEventData.value = anchorEvent;
    });

    function setLink(val) {
      if (val && !/^\w+[^\s]+(\.[^\s]+){1,}$/.test(val)) {
        ElMessage.error('请输入正确的地址');
        return;
      }

      const range = IEditor.selection.getAnchorRange();
      const selection = IEditor.selection.getSelection();
      if (!range || (range && range.collapsed)) {
        modelData.value.link = val;
      } else {
        createLink(selection, range, val);
      }
    }

    function clearLink() {
      if (!linkData.value) {
        return;
      }
      const { target } = anchorEventData.value;
      console.log('target', target);
      if (target.nodeName.toLowerCase() === 'a') {
        // 先使range包含这个元素
        const range = IEditor.selection.getRange();
        range.selectNodeContents(target);
        document.execCommand('unlink', false);
        // 折叠选区
        range.collapse(false);
        return;
      }
      modelData.value.link = '';
    }

    return {
      linkData,
      setLink,
      clearLink,
    };
  },
});
</script>
