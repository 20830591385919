function chooseModel(editor, format) {
  const { model, nodeModel } = editor.content.getPresentModel();
  switch (format) {
    case 'justifyContent':
      return nodeModel.data;
    default:
      return model;
  }
}

export const isMarkVal = (editor, format, value) => {
  const modelData = editor.content.getPresentModel();
  if (!modelData) {
    return false;
  }
  const { anchorEvent } = modelData;
  const target = anchorEvent && anchorEvent.target;
  if (target && target.nodeName === 'SPAN') {
    // 获取target的应用样式
    const style = window.getComputedStyle(target);
    return style[format] === value;
  }
  const model = chooseModel(editor, format);
  if (!model) {
    return false;
  }
  const modelStyle = model.style || (model.data && model.data.style);
  if (!modelStyle) {
    return false;
  }
  return modelStyle[format] === value;
};

export const toggleVal = (editor, menu) => {
  const { format, value } = menu;
  const model = chooseModel(editor, format);

  if (!model) {
    return;
  }
  const range = editor.selection.getRange();
  console.log('format', format);
  if (format !== 'text-decoration-line' || !range || range.collapsed) {
    const oldVal = model.style[format];
    editor.utils.undo.record({
      do: () => {
        model.style[format] = value;
      },
      undo: () => {
        model.style[format] = oldVal;
      },
    });
  } else {
    // console.log('format', format, value);
    const newFormat = value === 'line-through' ? 'strikeThrough' : 'underline';
    editor.utils.undo.record({
      isDoc: true,
    });
    document.execCommand('styleWithCSS', null, true);
    document.execCommand(newFormat);
    // const selection = editor.selection.getSelection();
    // editor.selection.updateContentStyle(selection, editor, { format, value });
  }
};
